export default [
  [
    require('./LISTEN_AND_PICK_IMAGE/Exercise1.json'),
    require('./LISTEN_AND_TYPE/Exercise2.json'),
    require('./LISTEN_AND_TYPE/Exercise3.json'),
  ],
  [
    require('./LISTEN_AND_SORT/Exercise4.json'),
    require('./LISTEN_AND_SELECT_TONE/Exercise5.json'),
    require('./LISTEN_AND_TYPE/Exercise6.json'),
  ],
  [
    require('./LISTEN_AND_SELECT_TONE/Exercise7.json'),
    require('./LISTEN_AND_PICK_ACCENTED_WORD/Exercise8.json'),
    require('./LISTEN_AND_TYPE/Exercise9.json'),
  ],
  [
    require('./LISTEN_AND_PICK_ACCENTED_WORD/Exercise10.json'),
    require('./LISTEN_AND_TYPE/Exercise11.json'),
    require('./LISTEN_AND_TYPE/Exercise12.json'),
  ],
  [
    require('./LISTEN_AND_PICK_ACCENTED_WORD/Exercise13.json'),
    require('./LISTEN_AND_PICK/Exercise14.json'),
    require('./LISTEN_AND_TYPE/Exercise15.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise16.json'),
    require('./LISTEN_AND_PICK/Exercise17.json'),
    require('./LISTEN_AND_PICK/Exercise18.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise19.json'),
    require('./LISTEN_AND_PICK/Exercise20.json'),
    require('./LISTEN_AND_TYPE/Exercise21.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise22.json'),
    require('./LISTEN_AND_PICK/Exercise23.json'),
    require('./LISTEN_AND_TYPE/Exercise24.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise25.json'),
    require('./LISTEN_AND_PICK/Exercise26.json'),
    require('./LISTEN_AND_PICK/Exercise27.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise28.json'),
    require('./LISTEN_AND_PICK/Exercise29.json'),
    require('./LISTEN_AND_PICK/Exercise30.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise31.json'),
    require('./LISTEN_AND_MARK_GAPS/Exercise32.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise33.json'),
    require('./LISTEN_AND_PICK/Exercise34.json'),
    require('./LISTEN_AND_PICK/Exercise35.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise36.json'),
    require('./LISTEN_AND_TYPE_AND_PICK/Exercise37.json'),
  ],
  [
    require('./LISTEN_AND_TYPE/Exercise38.json'),
    require('./LISTEN_AND_PICK/Exercise39.json'),
    require('./LISTEN_AND_TYPE_AND_PICK/Exercise40.json'),
  ]
]