/* Global imports */
import React, { useEffect, useState } from 'react'
import { Grid, Label, Segment } from 'semantic-ui-react'
import { keys } from 'ramda'

/* Local imports */
import { getClient } from 'Services/client'
import Text from 'Components/Text'
import exercise from '../Exercises/REVIEW_EXERCISE'

/* Component definition */
const ReviewExercise = ({ match }) => {
  const [answers, setAnswers] = useState(null)
  const { id, dayNumber } = match.params

  useEffect(() => {
    const subscription = getClient(id)
      .subscribe(doc => {
        const result = getExerciseAnswers(doc.answers, dayNumber)
        setAnswers(result)
      })

    return subscription.unsubscribe.bind(subscription)
  }, [dayNumber, id])

  return (
    <Segment>
      <header>
        <Text as="h1" notr>{exercise.title}</Text>
      </header>
      {
        answers &&
        <Grid padded>
          <Grid.Row>
            <Grid.Column width={6}>
              <Label>Übung 1</Label>
              <Text notr>{answers.step0.button}</Text>
            </Grid.Column>
            <Grid.Column width={10}>
              <Text as="p" notr>{answers.step0.comments}</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <Label>Übung 2</Label>
              <Text notr>{answers.step1.button}</Text>
            </Grid.Column>
            <Grid.Column width={10}>
              <Text as="p" notr>{answers.step1.comments}</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <Label>Übung 3</Label>
              <Text notr>{answers.step2.button}</Text>
            </Grid.Column>
            <Grid.Column width={10}>
              <Text as="p" notr>{answers.step2.comments}</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <Label>Übung 4</Label>
              <Text notr>{answers.step3.button}</Text>
            </Grid.Column>
            <Grid.Column width={10}>
              <Text as="p" notr>{answers.step3.comments}</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16}>
            <Grid.Column width={6}>
              <Label>Übung 5</Label>
              <Text notr>{answers.step4.button}</Text>
            </Grid.Column>
            <Grid.Column width={10}>
              <Text as="p" notr>{answers.step4.comments}</Text>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    </Segment>
  )
}

/* PropTypes */
ReviewExercise.propTypes = {
}

ReviewExercise.defaultProps = {}

/* Local utility functions */
const getExerciseAnswers = (answers, dayNumber) => {
  const answeredExercises = keys(answers)
  return answeredExercises.reduce((acc, exercise) => {
    if (exercise === `exercise-reviewExercise-${dayNumber}`) {
      return answers[exercise]
    }
    return acc
  }, null)
}

/* Local Styled Components */

export default ReviewExercise
