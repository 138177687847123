/* Global imports */
import React, { useState } from 'react'
import { string } from 'prop-types'
import { Button, Grid, Segment } from 'semantic-ui-react'

/* Local imports */
import { createProfessional } from 'Services/professional'
import Text from 'Components/Text'
import fields from 'Screens/ProfessionalEditor/fields'
import { initialState, renderField } from 'Screens/ProfessionalEditor'

/* Component definition */
const ProfessionalCreator = ({ id, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [professional, setProfessional] = useState(initialState)

  const onFieldChange = (key, valueKey = 'value') => (e, { [valueKey]: value }) => {
    setProfessional({
      ...professional,
      [key]: value,
    })
  }


  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    const onError = (error) => {
      console.error(error)
    }

    createProfessional(professional)
      .then(p => {
        setLoading(false)

        return p
      })
      .then(onSuccess)
      .catch(onError)
  }
  return (
    <form onSubmit={onSubmit}>
      <Segment textAlign="center">
        <Text as="h1">professional.creator.title</Text>
        <Grid columns={3}>
          {
            fields.map(renderField(loading, onFieldChange, professional))
          }
          <Grid.Row>
            <Grid.Column textAlign="center" width={16}>
              <Button
                primary
                type="submit">
                  {<Text>professional.creator.save</Text>}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </form>
  )
}

/* PropTypes */
ProfessionalCreator.propTypes = {
  id: string,
}

ProfessionalCreator.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

export default ProfessionalCreator
