import i18n from 'i18n-js'

import { DEFAULT_LOCALE, LOCALE } from 'Definitions/language'
import de from 'Assets/lang/de.json'

i18n.defaultLocale = DEFAULT_LOCALE
i18n.locale = LOCALE
i18n.translations = { de }

export default i18n
