import React from 'react'
import Routes from 'Screens'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import useSessionContext from 'Hooks/useSession'
import './App.css'

function App() {
  return (
    <div className="App">
      <useSessionContext.Provider>
        <NotificationContainer />
        <Routes />
      </useSessionContext.Provider>
    </div>
  );
}

export default App;
