/* Global imports */
import React, { useState, useRef } from 'react'
import { string } from 'prop-types'
import { Button, Grid, Loader, Segment } from 'semantic-ui-react'
import { Parser } from 'json2csv'
import { pluck, filter as rfilter, pipe as rpipe } from 'ramda'

/* Local imports */
import { getClientsBetweenDates, getClientsByProfessional } from 'Services/client'
import Text from 'Components/Text'
import Input from 'Components/Input'
import { getRefValue } from 'Utils'

const fields = [
  {
    label: 'Startdatum',
    value: 'startDate'
  },
  {
    label: 'Enddatum',
    value: 'endDate'
  },
  {
    label: 'Gesamtanzahl',
    value: 'totalClients'
  },
  {
    label: 'Anzahl Hoertraining 1',
    value: 'hoertraining1Clients'
  },
  {
    label: 'Anzahl Hoertraining Plus',
    value: 'hoertrainingPlusClients'
  },
  {
    label: 'Firmenname',
    value: 'companyName'
  },
  {
    label: 'Kundennummer',
    value: 'clientIds'
  },
  {
    label: 'Kunden Hoertraining',
    value: 'clientIdsTraining'
  },
  {
    label: 'Kunden Hoertraining Plus',
    value: 'clientIdsTrainingPlus'
  }
];

/* Component definition */
const Export = ({ professional, professionalList, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const startDateRef = useRef()
  const endDateRef = useRef()

  const [isDisabled, setDisabled] = useState(false);

  const validateDates = (e, data) => {
    const startDate = getRefValue(startDateRef)
    const endDate = getRefValue(endDateRef)
    
    setDisabled(new Date(startDate) > new Date(endDate))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    const startDate = getRefValue(startDateRef)
    const endDate = getRefValue(endDateRef)
    
    if (!professional) {
      getClientsBetweenDates(new Date(startDate), new Date(endDate))
        .subscribe(clients => {
          const dataToExport = professionalList.map(professional => {
            const clientsId = pluck('id')(clients.filter(client => client.professional_id === professional.id))
            
            if (clientsId.length === 0) {
              return (null)
            }

            const clientIdsTraining = rpipe(
              rfilter((c) => !!c.trainingActive && c.professional_id === professional.id),
              pluck('id')
            )(clients)
          
            const clientIdsTrainingPlus = rpipe(
              rfilter((c) => !!c.trainingPlusActive && c.professional_id === professional.id),
              pluck('id')
            )(clients)

            return (
              {
                "startDate": startDate,
                "endDate": endDate,
                "totalClients": clientsId.length,
                "hoertraining1Clients": clientIdsTraining.length,
                "hoertrainingPlusClients": clientIdsTrainingPlus.length,
                "companyName": professional.companyName,
                "clientIds": clientsId.toString(),
                "clientIdsTraining": clientIdsTraining.toString(),
                "clientIdsTrainingPlus": clientIdsTrainingPlus.toString()
              }
            )
          })
          setLoading(false)
          downloadCSV(dataToExport)
          onSuccess()
        })
    } else {
      getClientsByProfessional(professional.id, new Date(startDate), new Date(endDate))
        .subscribe(clients => {
          setLoading(false)
          downloadCSV(getDataToExport(clients, startDate, endDate, professional.companyName))
          onSuccess()
        })
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Segment textAlign="center">
        <Text as="h1">superadmin.dates.title</Text>
        <Grid padded>
        {
          loading ?
          <Loader/>
          :
          <>
            <Grid.Row>
              <Grid.Column width={6} textAlign="right">
                <Text as="h6">superadmin.dates.startDate</Text>
              </Grid.Column>
              <Grid.Column width={8} textAlign="left">
                <Input
                  placeholder='superadmin.dates.startDate'
                  ref={startDateRef}
                  onChange={validateDates}
                  required
                  type="date"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6} textAlign="right">
                <Text as="h6">superadmin.dates.endDate</Text>
              </Grid.Column>
              <Grid.Column width={8} textAlign="left">
                <Input
                  placeholder='superadmin.dates.endDate'
                  ref={endDateRef}
                  onChange={validateDates}
                  required
                  type="date"
                />
              </Grid.Column>
            </Grid.Row>
          </>
          }
          {isDisabled &&
            <Text as="h6" style={{color: 'red'}}>superadmin.dates.errorDate</Text>
          }
          <Grid.Row>
            <Grid.Column textAlign="center" width={16}>
              <Button
                disabled={loading || isDisabled}
                primary
                type="submit"
                >
                {<Text notr>Exportieren</Text>}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </form>
  )
}

/* PropTypes */
Export.propTypes = {
  id: string,
}

Export.defaultProps = {}

/* Local utility functions */
const getDataToExport = (clients, startDate, endDate, companyName) => {
  const clientsId = clients.map(c => c.id)
  const totalClients = clientsId.length

  const clientIdsTraining = rpipe(
    rfilter((c) => !!c.trainingActive),
    pluck('id')
  )(clients)

  const clientIdsTrainingPlus = rpipe(
    rfilter((c) => !!c.trainingPlusActive),
    pluck('id')
  )(clients)

  return {
    "startDate": startDate,
    "endDate": endDate,
    "totalClients": totalClients,
    "hoertraining1Clients": clientIdsTraining.length,
    "hoertrainingPlusClients": clientIdsTrainingPlus.length,
    "companyName": companyName,
    "clientIds": clientsId.toString(),
    "clientIdsTraining": clientIdsTraining.toString(),
    "clientIdsTrainingPlus": clientIdsTrainingPlus.toString()
  }
}

const downloadCSV = data => {
  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(data)
  const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csv)

  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "Kundenverwaltung_export_ID.csv");
  document.body.appendChild(link); // Required for FF

  link.click();
}

/* Local Styled Components */

export default Export
