import { db } from '../common/database'
import { collection as getCollection, doc } from 'rxfire/firestore'
import { map } from 'rxjs/operators'
import { assign } from 'crocks'
import firebase from 'firebase'

const collection = db.collection('clients')

export function getAllClients() {
  return getCollection(collection.orderBy('createdAt', 'desc'))
    .pipe(map(docs => docs.map(doc => assign(doc.data(), { id: doc.id }))))
}

export function getClients(id) {
  return getCollection(collection.orderBy('firstName').where('professional_id', '==', id))
    .pipe(map(docs => docs.map(doc => assign(doc.data(), { id: doc.id }))))
}

export function getClientsByProfessional(id, startDate, endDate) {
  return getCollection(collection.orderBy('createdAt').where('professional_id', '==', id)
     .where("createdAt", ">=", startDate).where("createdAt", "<=", endDate))
     .pipe(map(docs => docs.map(doc => assign(doc.data(), { id: doc.id }))))
}
export function getClientsBetweenDates(startDate, endDate) {
  return getCollection(collection.orderBy('createdAt')
     .where("createdAt", ">=", startDate).where("createdAt", "<=", endDate))
     .pipe(map(docs => docs.map(doc => assign(doc.data(), { id: doc.id }))))
}

export function getClient(id) {
  return doc(collection.doc(id))
    .pipe(map(doc => assign(doc.data(), { id: doc.id })))
}

export function createClient(data) {
  return collection
    .add(data)
}

export function deleteClient(id) {
  return collection
    .doc(id)
    .delete()
}

export function updateClient(id, data) {
  return collection
    .doc(id)
    .set(data)
}

export function generateAccessPlusCode() {
  return firebase.functions().httpsCallable('generatePlusCode')
}