/* Global imports */
import React, { useEffect, useState } from 'react'
import { Grid, GridColumn } from 'semantic-ui-react'
import { pathOr, pluck, countBy, toUpper, pipe, isNil, isEmpty, filter, map, reduce } from 'ramda'

/* Local imports */
import { SUPER_ADMIN_OVERVIEW } from 'Definitions/routes'
import { getStepCount, getCompletedStepCount } from 'Utils'
import { getAllClients } from 'Services/client'
import BackButton from 'Components/BackButton'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import exercises from '../Exercises'
import exercisesPlus from '../ExercisesPlus'

const CLIENTS_ANALYTICS_INITIAL_STATE = {
  active: 0,
  total: 0,
  rightCurves: null,
  leftCurves: null,
  completedTrainingsCount: 0,
  completedTrainingsPlusCount: 0,
}

/* Component definition */
const ClientAnalytics = ({ history }) => {
  const [exerciseAnalytics, setExerciseAnalytics] = useState([])
  const [exercisePlusAnalytics, setExercisePlusAnalytics] = useState([])
  const [clientsAnalytics, setClientsAnalytics] = useState(CLIENTS_ANALYTICS_INITIAL_STATE )

  useEffect(() => {
    const subscription = getAllClients()
      .subscribe(clients => {
        setExerciseAnalytics(getAllAnalyticsByDay(clients))
        setExercisePlusAnalytics(getAllPlusAnalyticsByDay(clients))
        setClientsAnalytics(getAllClientsAnalytics(clients))
      })

    return subscription.unsubscribe.bind(subscription)
  }, [])

  return (
    <>
      <div  style={{position: 'relative', display: 'flex', cursor: 'pointer'}}>
        <BackButton history={history} route={SUPER_ADMIN_OVERVIEW } />
       </div>
      <Grid padded>
        <Grid.Row style={{ display: 'flex', padding: '14px', justifyContent: 'space-between'}}>
          <Text as="h1" className="notPrintable" notr>Detailansicht</Text>
        </Grid.Row>
        <Grid.Row >
          <Grid.Column>
            <Text as="h2">analytics.title</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="notPrintable" columns={2}>
          <GridColumn width={11}>
            <Spacer height="14px"/>
            <Grid padded>
              <Grid.Row>
                <Grid.Column tablet={16} widescreen={8}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Text as="h2" notr>Hörtraining</Text>
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Text style={{fontWeight: 'bold', fontSize: '20px', color: '#0082BA'}} notr>Anteil korrekter Antworten</Text>
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Text style={{fontWeight: 'bold', fontSize: '20px', color: '#0082BA'}} notr>Anteil Nutzer mit Wiederholung</Text>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {
                    exerciseAnalytics.map(renderDayRow)
                  }
                </Grid.Column>
                <Grid.Column tablet={16} widescreen={8}>
                <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Text as="h2" notr>Hörtraining  Plus</Text>
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Text style={{fontWeight: 'bold', fontSize: '20px', color: '#0082BA'}} notr>Anteil korrekter Antworten</Text>
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Text style={{fontWeight: 'bold', fontSize: '20px', color: '#0082BA'}} notr>Anteil Nutzer mit Wiederholung</Text>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {
                    exercisePlusAnalytics.map(renderDayRow)
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </GridColumn>
            <Grid.Column style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}} width={5}>
            <Text as="h2">analytics.userOverview</Text>
            <Text style={{fontWeight: 'bold', color: '#0082BA'}} as="h3">analytics.totalClients</Text>
            <Text  notr>{`${clientsAnalytics.active} / ${clientsAnalytics.total}`}</Text>
            <Spacer height="48px"/>
            <Text style={{fontWeight: 'bold', color: '#0082BA'}} as="h3">analytics.hearingLossRight</Text>
            {
              clientsAnalytics.rightCurves &&
                Object.keys(clientsAnalytics.rightCurves).map((key, index) => (
                  <Text key={index} notr>{`${key}:   ${clientsAnalytics.rightCurves[key.toString()]}`}</Text>
                ))
            }
            <Spacer height="48px"/>
            <Text style={{fontWeight: 'bold', color: '#0082BA'}} as="h3">analytics.hearingLossLeft</Text>
            {
              clientsAnalytics.leftCurves &&
                Object.keys(clientsAnalytics.leftCurves).map((key, index) => (
                  <Text key={index} notr>{`${key}:   ${clientsAnalytics.leftCurves[key.toString()]}`}</Text>
                ))
            }
            <Spacer height="48px"/>
            <Text style={{fontWeight: 'bold', color: '#0082BA'}} as="h3">analytics.completedTrainings</Text>
            <Text notr>{`${clientsAnalytics.completedTrainingsCount}`}</Text>
            <Spacer height="48px"/>
            <Text style={{fontWeight: 'bold', color: '#0082BA'}} as="h3">analytics.completedTrainingsPlus</Text>
            <Text notr>{`${clientsAnalytics.completedTrainingsPlusCount}`}</Text>
            </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

/* PropTypes */
ClientAnalytics.propTypes = {}

ClientAnalytics.defaultProps = {}

/* Local utility functions */
const getCorrectCount = (answers, exerciseNumbers) => exerciseNumbers.reduce(
  (total, current) => {
    const totalCorrect = pathOr(0, [`exercise${current}`, 'totalCorrect'], answers)

    return total + totalCorrect
}, 0)

const getCorrectExerciseCount = (answers, exercise) => {
    const totalCorrect = pathOr(0, [`exercise${exercise.index}`, 'totalCorrect'], answers)

    return  totalCorrect
}

const getAllCorrectCount = (dayExercises, clients, isPlus) => {
  return clients
    .map(c => getCorrectCount(isPlus ? c.answersPlus : c.answers, dayExercises.map(x => x.index)))
    .reduce((total, current) => total + current)
}

const getAllCorrectExerciseCount = (exercise, clients, isPlus) => {
  return clients
    .map(c => getCorrectExerciseCount(isPlus ? c.answersPlus : c.answers, exercise))
    .reduce((total, current) => total + current)
}

const getEachExerciseCorrectCount = (dayExercises, clients, isPlus) => {
  return dayExercises.map(exercise => {
    return {
      exerciseNumber: exercise.index,
      repetitionAmount: getRelativeRepetitions(exercise, clients, isPlus),
      totalCorrect: getAllCorrectExerciseCount(exercise, clients, isPlus),
      totalAnswered: getAllCompletedStepsCount(exercise, clients, isPlus) 
    }
  })
}

const getAllCompletedStepsCount = (exercise, clients, isPlus) => {
  return clients
    .map(c => getCompletedStepCount(exercise, isPlus ? c.answersPlus : c.answers ))
    .reduce((total, current) => total + current)
}

const getRelativeRepetitions = (exercise, clients, isPlus) => {
  const totalClientsWithRepetition = clients
    .map(c => {
      const repetitions = pathOr(0, [`exercise${exercise.index}`, 'repetitions'], isPlus ? c.answersPlus : c.answers)
      return repetitions > 0;
    })
    .reduce((total, current) => total + (current ? 1 : 0), 0);
  return clients.length === 0 ? 0 : (Math.round(totalClientsWithRepetition * 100 / clients.length))
}

const getAllPlusAnalyticsByDay = (clients) => {
  return exercisesPlus.map((dayExercises, index) => {
    return {
      correctSteps: getAllCorrectCount(dayExercises, clients, true),
      dayNumber:    index + 1,
      totalSteps:   dayExercises.reduce((total, current) => total + getAllCompletedStepsCount(current, clients, true), 0),
      exerciseResults:    getEachExerciseCorrectCount(dayExercises, clients, true),
    }
  })
}

const getAllAnalyticsByDay = (clients) => {
  return exercises.map((dayExercises, index) => {
    return {
      correctSteps: getAllCorrectCount(dayExercises, clients, false),
      dayNumber:    index + 1,
      totalSteps:   dayExercises.reduce((total, current) => total + getAllCompletedStepsCount(current, clients, false), 0),
      exerciseResults:    getEachExerciseCorrectCount(dayExercises, clients, false),
    }
  })
}

const completedPlusTrainingsCount = (clients) => {
  const plusExercisesStepCount = getExercisesStepsCount(exercisesPlus)
  return clients.reduce((total, client) => {
    const completedSteps = getAnsweredExerciseStepsCount(client.answersPlus ? client.answersPlus : {}, exercisesPlus)
    const comp = completedSteps === plusExercisesStepCount ? 1 : 0;
    return total + comp
  }, 0)
}

const completedTrainingsCount = (clients) => {
  const exerciseStepsCount = getExercisesStepsCount(exercises)
  return clients.reduce((total, client) => {
    const completedSteps = getAnsweredExerciseStepsCount(client.answers ? client.answers : {}, exercises)
    const comp = completedSteps === exerciseStepsCount ? 1 : 0;
    return total + comp
  }, 0)
}

const getExercisesStepsCount = (days) => {
  return days.reduce((total, day) => {
    const dayStepCount = day.reduce((dayTotal, exercise) => {
      return dayTotal + getStepCount(exercise)
    }, 0)
    return total + dayStepCount;
  }, 0)
}

const getAnsweredExerciseStepsCount = (results, days) => {
  return days.reduce((total, day) => {
    const dayAnsweredStepCount = day.reduce((dayTotal, exercise) => {
      return dayTotal + getCompletedStepCount(exercise, results)
    }, 0)
    return total + dayAnsweredStepCount;
  }, 0)
}

const getCurves = key => pipe(
  pluck(key),
  filter(i => !isEmpty(i)),
  filter(i => !isNil(i))
 )

const getAllClientsAnalytics = (clients) => {
  const activeClients = clients.filter(c => c.active).length
  const rightEarBananaTypes = countBy((toUpper))(getCurves('bananaType')(clients).sort())
  const leftEarBananaTypes = countBy((toUpper))(getCurves('bananaTypeLeft')(clients).sort())
  const completedTrainings = completedTrainingsCount(clients)
  const completedPlusTrainings = completedPlusTrainingsCount(clients)

  return ({
    active: activeClients,
    total: clients.length,
    rightCurves: rightEarBananaTypes,
    leftCurves: leftEarBananaTypes,
    completedTrainingsCount: completedTrainings,
    completedTrainingsPlusCount: completedPlusTrainings,
  })
}

const renderDayRow = ({ exerciseResults, correctSteps, dayNumber, totalSteps }) =>(
    <Grid.Row key={dayNumber}>
      <Grid.Column style={{ paddingTop: '16px'}} width={16}>
        <Grid padded>
          <Grid.Row>
            <Grid.Column width={6}>
              <Text style={{fontWeight: 'bold', fontSize: '20px', color: '#0082BA'}} notr>Tag {dayNumber}</Text>:
            </Grid.Column>
            <Grid.Column width={10}>
              <Text style={{fontWeight: 'bold', fontSize: '20px', color: '#0082BA'}} notr>{totalSteps === 0 ? 0 : Math.round(correctSteps * 100 / totalSteps)}%</Text>
            </Grid.Column>
          </Grid.Row>
          {
            exerciseResults.map(({ exerciseNumber, totalCorrect, totalAnswered, repetitionAmount })=> (
              <Grid.Row key={exerciseNumber} >
                <Grid.Column width={6}>
                  <Text notr>Übung {exerciseNumber + 1}</Text>:
                </Grid.Column>
                <Grid.Column width={5}>
                  <Text notr>{totalAnswered === 0 ? 0 : Math.round(totalCorrect * 100 / totalAnswered)}%</Text>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Text notr>{repetitionAmount}%</Text>
                </Grid.Column>
              </Grid.Row>
            ))
          } 
        </Grid>
    </Grid.Column>
    </Grid.Row>
) 

/* Local Styled Components */

export default ClientAnalytics
