/* Global imports */
import React from 'react'
import styled from 'styled-components'

/* Local imports */

/* Component definition */
const BackButton = ({ history, route }) => {

  return (
    <SBackButtonWrapper
      onClick={() => route ? history.push(route()): history.goBack()
    }>
      <img alt="arrow" src={require('Assets/icons/arrow-left.svg')} height="32px" width="32px" />
      <SButton>Zurück</SButton>
    </SBackButtonWrapper>
  )
}
/* PropTypes */
BackButton.propTypes = {}
BackButton.defaultProps = {}

/* Local utility functions */

/* Styles */
const SButton = styled.button`
  background-color: transparent !important;
  border: none;
  border-bottom: ${({ active }) =>  active && '1px solid #0082BA'};
  color: #0082BA;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 32px;
  letter-spacing: 1.5px;
  line-height: 21px;
  margin: 0;
  padding:0;
  padding-top: 4px;
  margin-right: 16px;
  text-transform: uppercase;
`
const SBackButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding-bottom: 24px;
`

export default BackButton