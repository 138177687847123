/* Global imports */
import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, Image, Input } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import ReactQuill from 'react-quill'
import { assign, getPathOr, setPath } from 'crocks'
import 'react-quill/dist/quill.snow.css'

// import { } from 'prop-types'

/* Local imports */
import { PROFESSIONAL_OVERVIEW } from 'Definitions/routes'
import { getRefFile } from 'Utils'
import { isFileImage } from 'Utils/professional'
import {
  getProfessional,
  updateProfessional,
  uploadProfessionalImage,
  uploadSettingsLogo,
} from 'Services/professional'
import ColorPicker from 'Components/ColorPicker'
import Text from 'Components/Text'
import ColorBox from 'Components/ColorBox'
import useSessionContext from 'Hooks/useSession'
import fields from 'Screens/ProfessionalEditor/fields'
import { initialState as userInitialState, renderField } from 'Screens/ProfessionalEditor'

const initialState = {
  ...userInitialState,
  settings: {
    aboutUs: '',
    imprint: '',
    image: {
      downloadUrl: '',
    },
    logo: {
      downloadUrl: '',
    },
    openingHours:  '',
    primaryColor:   '',
    secondaryColor: '',
  }
}


/* Component definition */
const ProfessionalSettings = ({ history }) => {
  const [ loading, setLoading] = useState(false)
  const [userData, setUserData] = useState(initialState)
  const logoRef = useRef()
  const imageRef = useRef()
  const { user } = useSessionContext()
  const id = user ? user.professional_id : null

  const onFieldChange = (key) => (e, { value }) => {
    setUserData(oldData => setPath(key.split('.'), value, oldData))
  }

  const uploadLogo = () => {
    const file = getRefFile(logoRef)

    if(!isFileImage(file)){
      NotificationManager.info('You can only upload images')
      return
    }

    uploadSettingsLogo(id, getRefFile(logoRef))
      .then(imageData => {
        onFieldChange('settings.logo')(null, { value: imageData })
      })
      .catch(console.error.bind(null, 'Error has ocurred:'))
  }

  const uploadImage = () => {
    const file = getRefFile(imageRef)

    if(!isFileImage(file)){
      NotificationManager.info('You can only upload images')
      return
    }

    uploadProfessionalImage(id, getRefFile(imageRef))
      .then(imageData => {
        onFieldChange('settings.image')(null, { value: imageData })
      })
      .catch(console.error.bind(null, 'Error has ocurred:'))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    setLoading(true)

    updateProfessional(id, userData)
      .then(() => {
        setLoading(false)
        history.push(PROFESSIONAL_OVERVIEW())
        NotificationManager.success('Einstellungen wurden erfolgreich aktualisiert')
      })
  }

  useEffect(() => {
    if(id) {
      const subscription = getProfessional(id)
        .subscribe(doc => {
          const professionalSettings = doc || {}

          setUserData(assign(professionalSettings, initialState))
          setLoading(false)
      })
    return subscription.unsubscribe.bind(subscription)
    }
  }, [id])

  return (
      <form  className="editor" onSubmit={onSubmit}>
        <Text as="h1">professional.settings.title</Text>
        <Grid padded>
          {
            fields.map(renderField(loading, onFieldChange, userData))
          }
          <Text as="h6">professional.settings.color</Text>
          <Grid.Row>
            <Grid.Column mobile={6} tablet={6} computer={6}>
              <Text as="h6">professional.settings.primaryColor</Text>
              <ColorBox color={userData.settings.primaryColor}></ColorBox>
            </Grid.Column>
            <Grid.Column>
              <ColorPicker
                color={userData.settings.primaryColor}
                disabled={loading}
                onChange={onFieldChange('settings.primaryColor')}
              />
          </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={6} tablet={6} computer={6}>
              <Text as="h6">professional.settings.secondaryColor</Text>
              <ColorBox color={userData.settings.secondaryColor}></ColorBox>
            </Grid.Column>
            <Grid.Column>
              <ColorPicker
                color={userData.settings.secondaryColor}
                disabled={loading}
                onChange={onFieldChange('settings.secondaryColor')}
              />
            </Grid.Column>
          </Grid.Row>
          <Text as="h6">professional.settings.logo</Text>
          <Grid.Row>
            <Grid.Column mobile={6} tablet={6} computer={6}>
              <Image 
                alt="Logo"
                src={getPathOr('', ['settings', 'logo', 'downloadUrl'], userData)}
                size="small"
              />
             </Grid.Column>
              <Grid.Column>
              <Input
                disabled={loading}
                onChange={uploadLogo}
                ref={logoRef}
                type="file"
              />
            </Grid.Column>
          </Grid.Row>
          <Text as="h6">professional.settings.image</Text>
          <Grid.Row>
            <Grid.Column mobile={6} tablet={6} computer={6}>
              <Image
                alt="Image"
                src={getPathOr('', ['settings', 'image', 'downloadUrl'], userData)}
                size="small"
              />
             </Grid.Column>
              <Grid.Column>
             <Input
                disabled={loading}
                onChange={uploadImage}
                ref={imageRef}
                type="file"
              />
            </Grid.Column>
          </Grid.Row>
          <Text as="h3" notr>Öffnungszeiten</Text>
          <Grid.Row>
            <Grid.Column width={16}>
              <ReactQuill
                disabled={loading}
                onChange={value => onFieldChange('settings.openingHours')(null, { value })}
                value={userData.settings.openingHours ? userData.settings.openingHours : ''}
                style={{ height: '300px', marginBottom: '40px', width: '600px', paddingRight: '8px' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Text as="h3">professional.settings.aboutUs</Text>
          <Grid.Row>
            <Grid.Column width={16}>
              <ReactQuill
                disabled={loading}
                onChange={value => onFieldChange('settings.aboutUs')(null, { value })}
                value={userData.settings.aboutUs ? userData.settings.aboutUs : ''}
                style={{ height: '300px', marginBottom: '40px', width: '600px', paddingRight: '8px' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Text as="h3">professional.settings.imprint</Text>
          <Grid.Row>
            <Grid.Column width={16}>
              <ReactQuill
                disabled={loading}
                onChange={value => onFieldChange('settings.imprint')(null, { value })}
                value={userData.settings.imprint ? userData.settings.imprint : '' }
                style={{ height: '300px', marginBottom: '40px', width: '600px', paddingRight: '8px' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={() => history.push(PROFESSIONAL_OVERVIEW())}
                secondary
              >
                <Text>professional.cancel</Text>
              </Button>
              <Button disabled={loading} primary type="submit">
                <Text>professional.creator.save</Text>
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </form>
  )
}

/* PropTypes */
ProfessionalSettings.propTypes = {}

ProfessionalSettings.defaultProps = {}

/* Local utility functions */

export default ProfessionalSettings
