/* Global imports */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

/* Local imports */
import useSessionContext from 'Hooks/useSession'
import { LOGIN } from 'Definitions/routes'
import Header from 'Components/Header'


/* Component definition */
export const ProtectedRoute = ({ component: Component, condition, history, ...rest }) => {
  let { user } = useSessionContext()

  if (!user) {
    user = JSON.parse(localStorage.getItem('authUser'))
  }

  if(condition && !condition(user)) {
    return <Redirect to="/" />
  }

  return (
    <Route
      {...rest}
      render={ props => {
        if (user) {

          return (
            <div>
              <Header history={history} />
              <Component {...props} />
            </div>
          )
        }
        return <Redirect to={LOGIN()} />
      }}
    />
  )
}
/* PropTypes */

/* Local utility functions */

export default withRouter(ProtectedRoute)
