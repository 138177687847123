import getRoute from 'Utils/routeCreator'

export const LOGIN = getRoute('/login')

export const RESET_PASSWORD = getRoute('/reset-password')

export const SUPER_ADMIN_OVERVIEW = getRoute('/admin')

export const PROFESSIONAL_EDIT = getRoute('/professional/:id/edit')

export const PROFESSIONAL_OVERVIEW = getRoute('/professional')

export const PROFESSIONAL_SETTINGS = getRoute(`/professional/settings`)

export const CLIENT_EDIT = getRoute('/client/:id/edit')

export const TRAINING_OVERVIEW = getRoute('/client/:id/overview')

export const TRAINING_DAY = getRoute('/client/:id/training-day/:dayIndex')

export const EXERCISE = getRoute('/client/:id/training-day/:dayIndex/exercise/:exerciseIndex')

export const REVIEW_EXERCISE = getRoute('/client/:id/review-exercise/:dayNumber')

export const CLIENT_ANALYTICS = getRoute('/client/:id/analytics')

export const ALL_CLIENTS_ANALYTICS = getRoute('/clients/analytics')