import { useState } from 'react'
import createUseContext from 'constate'
import * as userService from 'Services/user'

export default createUseContext(function useSession() {
  const [user, setUser] = useState(null)
  const [isShowInactive, setShowInactive] = useState(false)

  userService.onStateChange()
    .subscribe(u => {
      if (!user || u.uid !== user.uid) {
        localStorage.setItem('authUser', JSON.stringify(u))
        setUser(u)
      }
    })

  const login = (email, password) => {
    return userService.login(email, password)
  }

  const logout = () => {
    return userService.logout()
      .then((data) => {
        setUser(null)
        localStorage.removeItem('authUser')

        return data
      })
  }

  return {
    user,
    login,
    logout,
    isShowInactive, // TODO: Make it persistant
    setShowInactive // TODO: Make it persistant
  }
})
