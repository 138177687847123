import { useCallback, useEffect, useState } from 'react'

const useAudio = (audioFile, allowReplay = false, isPlus, optional) => {
  const [file, setFile] = useState()
  const [element] = useState(new Audio())
  const [hasBeenPlayed, setHasBeenPlayed] = useState(false)

  const stop = useCallback(() => {
    element.pause()
    element.currentTime = 0
  }, [element])

  const play = () => {
    if (allowReplay) {
      return element.paused ? element.play() : stop()
    }

    if (!hasBeenPlayed) {
      element.play()
      setHasBeenPlayed(true)
    }
  }

  const load = useCallback((filename) => {
    setHasBeenPlayed(false)
    stop()
    setFile(requireAudio(filename, isPlus, optional))
  }, [isPlus, stop, optional])

  useEffect(() => {
    element.src = file
    element.load()
  }, [element, file, load])

  useEffect(() => {
    if (audioFile) {
      load(audioFile)
    }
  }, [audioFile, load])

  // Stop playing the audio file if the component is destroyed:
  useEffect(() => {
    return () => element.pause()
  }, [element])

  return { disabled: hasBeenPlayed, load, play }
}

export default useAudio

const requireAudio = (filename, isPlus, optional) => {
  const folder = isPlus ? optional ? 'audiofilesPlus/optional': 'audiofilesPlus' : 'audiofiles' 

  return filename ? require('Assets/'+folder+'/'+filename) : null
}
