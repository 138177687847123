/* Global imports */
import React, { useEffect, useState } from 'react'
import { object } from 'prop-types'
import { Button, Checkbox, Modal, Pagination, Table } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { filter, find, flatten, keys, pipe, whereEq } from 'ramda'
import styled from 'styled-components'
import { format } from 'date-fns'

/* Local imports */
import {
  CLIENT_EDIT,
  CLIENT_ANALYTICS,
  TRAINING_OVERVIEW,
} from 'Definitions/routes'
import { getClients, updateClient } from 'Services/client'
import Text from 'Components/Text'
import ClientCreator from 'Components/ClientCreator'
import Input from 'Components/Input'
import Spacer from 'Components/Spacer'
import { getClientFullName } from 'Utils/client'
import useSessionContext from 'Hooks/useSession'
import exercises from '../Exercises'


const PAGINATION_NUMBER = 25

/* Component definition */
const ClientList = () => {
  const [loading, setLoading] = useState(false)
  const [clientList, setClientList] = useState([])
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [hideInactive, setHideInactive] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [searchText, setSearchText] = useState()
  const { user, isShowInactive, setShowInactive } = useSessionContext()

  const start = (activePage * PAGINATION_NUMBER) - PAGINATION_NUMBER
  const final = activePage * PAGINATION_NUMBER

  const onToggleState = (field, { active, trainingActive, trainingPlusActive, id, ...rest }) => () => {
    setLoading(true)
    const newActiveState = {
      trainingActive: field === 'trainingActive' ? !trainingActive : !!trainingActive,
      trainingPlusActive: field === 'trainingPlusActive' ? !trainingPlusActive : !!trainingPlusActive
    }

    updateClient(id, {
      ...rest,
      active: newActiveState.trainingActive || newActiveState.trainingPlusActive,
      trainingActive: newActiveState.trainingActive,
      trainingPlusActive: newActiveState.trainingPlusActive,
    })
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const onSaveNew = (result) => {
    setCreateModalOpen(false)
  }
  const handlePaginationChange = ( e, { activePage: active }) => {
    setActivePage(active)
  }

  const findWithText = (client) => {
    const searchExp = new RegExp(searchText, 'i')
  
    return searchExp.test(getClientFullName(client))
      || searchExp.test(client.customerNumber)
      || searchExp.test(client.id)
  }

  useEffect(() => {
    isShowInactive && setHideInactive(true)
  }, [isShowInactive])

  useEffect(() => {
    if(user) {
      const subscription = getClients(user.professional_id)
            .subscribe(setClientList)

      return subscription.unsubscribe.bind(subscription)

    }
  }, [user])

  return (
    <div style={{ position: 'relative', padding:'0px 8px'}}>
      <Text as="h1" notr>Kunden</Text>
      <Modal
        closeIcon
        onClose={() => setCreateModalOpen(false)}
        open={createModalOpen}
        size="tiny"
        trigger={
          <div style={{postion: 'relative'}}>
          <SButton secondary onClick={() => setCreateModalOpen(true)}>
            <Text>superadmin.overview.buttons.new</Text>
          </SButton>
        </div> 
        }
      >
        <ClientCreator onSuccess={onSaveNew} />
      </Modal>
      <Input
        placeholder="search.professional"
        onChange={(e, { value }) => setSearchText(new RegExp(value))}
        style={{width: '250px', fontSize: '14px'}}
        type="text"
      />
      <Table collapsing style={{ width: '100%'}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.clientName</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.active</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.customerNumber</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.accessCode</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.accessCodePlus</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.lastActivity</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.period</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.periodPlus</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.trainingActive</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>professional.overview.tableHeaders.trainingPlusActive</Text>
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            clientList
              .filter(c => findWithText(c))
              .filter(c => hideInactive ? c.active : true)
              .slice(start, final)
              .map(
              (client, index) =>              
                <Table.Row style={getRowStyle(client)} key={`${client.id} + ${index}`}>
                  <Table.Cell>
                    <NavLink to={CLIENT_EDIT(client.id)}>{ getClientFullName(client) }</NavLink>
                  </Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      checked={client.active}
                      disabled
                    />
                  </Table.Cell>
                  <Table.Cell>{ client.customerNumber }</Table.Cell>
                  <Table.Cell>{ client.accessCode }</Table.Cell>
                  <Table.Cell>{ client.accessCodePlus }</Table.Cell>
                  <Table.Cell className="collapsing">
                    <p style={{"margin": 0}}>
                      {`${getLastActivityDate(client)}`}
                    </p>
                    <p style={{"margin": 0}}>
                      { `${getLastActivity(client)}` }
                    </p>
                    <p style={{"margin": 0}}>
                      {`${getLastPlusActivity(client)}`}
                    </p>
                  </Table.Cell>
                  <Table.Cell>{ getPeriod(client) }</Table.Cell>
                  <Table.Cell>{ getPeriodPlus(client)}</Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      checked={client.trainingActive}
                      disabled={loading}
                      onChange={onToggleState('trainingActive', client)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      checked={client.trainingPlusActive}
                      disabled={loading}
                      onChange={onToggleState('trainingPlusActive', client)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '10px'}}>
                          <NavLink to={TRAINING_OVERVIEW(client.id)}>
                            <Button style={{ width: '230px'}}  color="green">
                              <Text>professional.overview.buttons.results</Text>
                            </Button>
                          </NavLink>
                          <Spacer height="8px" />
                          <NavLink to={{
                            pathname: TRAINING_OVERVIEW(client.id),
                            state: { isPlus: true }
                          }}>
                            <Button style={{ width: '230px'}} color="green">
                              <Text>professional.overview.buttons.resultsPlus</Text>
                            </Button>
                          </NavLink>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                          <NavLink to={CLIENT_ANALYTICS(client.id)}>
                            <Button style={{ width: '200px'}}  color="green">
                              <Text>professional.overview.buttons.analytics</Text>
                            </Button>
                          </NavLink>
                          <Spacer height="8px" />
                          <NavLink to={{
                            pathname: CLIENT_ANALYTICS(client.id),
                            state: { isPlus: true }
                          }}>
                            <Button style={{ width: '200px'}} color="green">
                              <Text>professional.overview.buttons.analyticsPlus</Text>
                            </Button>
                          </NavLink>
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
            )
          }
        </Table.Body>
      </Table>
      <SPaginationWrapper>
        <Checkbox
          checked={hideInactive}
          onChange={(e, { checked }) => { setHideInactive(checked); setShowInactive(true); }}
          label="Inaktive Kunden ausblenden"
        />
        <SPagination
          activePage={activePage}
          boundaryRange={0}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={handlePaginationChange}
          totalPages={Math.ceil(clientList.length / PAGINATION_NUMBER)}
        />
      </SPaginationWrapper>
    </div>
  )
}

/* PropTypes */
ClientList.propTypes = {
  history: object,
}

ClientList.defaultProps = {}

/* Local utility functions */
const getLastAnsweredExercise = client => {
  if (!client.answers) {
    return 0
  }

  const nameRegExp = /exercise([0-9]+)/

  return pipe(
    keys,
    filter(x => nameRegExp.test(x)),
    x => x.length - 1
  )(client.answers)
}

const getLastAnsweredPlusExercise = client => {
  if (!client.answersPlus) {
    return 0
  }

  const nameRegExp = /exercise([0-9]+)/

  return pipe(
    keys,
    filter(x => nameRegExp.test(x)),
    x => x.length - 1
  )(client.answersPlus)
}

const getExerciseByIndex = exercises => index => {
  return pipe(
    flatten,
    find(whereEq({ index }))
  )(exercises)
}

const getLastPlusActivity = user => {
  return pipe(
    getLastAnsweredPlusExercise,
    getExerciseByIndex(exercises),
    ({ day, index: exercise }) => `Training Plus: Tag ${day}, Übung ${exercise + 1}`
  )(user)
}

const getLastActivity = user => {
  return pipe(
    getLastAnsweredExercise,
    getExerciseByIndex(exercises),
    ({ day, index: exercise }) => `Training: Tag ${day}, Übung ${exercise + 1}`
  )(user)
}

const getLastActivityDate = user => {
  return `${user.lastActivity ? `${user.lastActivity}` : '' }`
}

const getPeriod = (client) => {
  const startDate = format(new Date(client.startDate), 'MM/dd/yyyy') || ''
  const endDate = format(new Date(client.endDate), 'MM/dd/yyyy') || ''
  format(new Date(2014, 1, 11), 'MM/dd/yyyy')
  return `${startDate} - ${endDate}`
}

const getPeriodPlus = (client) => {
  // edge case check for old database entries, that don't have plus date
  if (!client.startDatePlus) {
    return '-'
  }

  const startDate = format(new Date(client.startDatePlus), 'MM/dd/yyyy') || ''
  const endDate = format(new Date(client.endDatePlus), 'MM/dd/yyyy') || ''
  format(new Date(2014, 1, 11), 'MM/dd/yyyy')
  return `${startDate} - ${endDate}`
}

const getRowStyle = ({ active }) => {
  return {
    backgroundColor: active ? '' : '#fff3f3',
    padding: '0px !important'

  }
}

/* Local Styled Components */
const SButton = styled(Button)`
  position: absolute;
  right: 12px;
  top: 0px;
`

const SPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const SPagination = styled(Pagination)`
  justify-content: center !important;
  width: unset !important;
`

export default ClientList
