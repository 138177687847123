const defaultTo = (defaultValue, value) => [null, undefined].includes(value) ? defaultValue : value

export default function getRoute(string = '') {
  const regexp = /(:\w+\??)/g
  const params = string.match(regexp) || []
  const mandatoryArgLength = params.filter(param => !/\?/.test(param)).length

  const fn = (...args) => {
    if (args.length < mandatoryArgLength) {
      throw Error(`Expected ${mandatoryArgLength} and got ${args.length} arguments for path '${string}'`)
    }

    let i = 0

    return string
      .split(regexp)
      .reduce((total, part) =>
              total.concat(
                params.includes(part) ? defaultTo('', args[i++]) : part
              )
             )
  }

  fn.PATH = string
  return fn
}
