/* Global imports */
import React from 'react'
import styled from 'styled-components'

// import { } from 'prop-types'

/* Local imports */
import { setAudioFile } from 'Utils'
import useAudio from 'Hooks/useAudio'
import Button from 'Components/Button'
import Text from 'Components/Text'

/* Component definition */
const PlayButton = ({ replay, exercise, step, stage, isPlus, optional, ...rest }) => {
  const { disabled, play } = useAudio(setAudioFile(exercise, step, stage), replay, isPlus, optional)

  return (
    <SButton
      color="green"
      disabled={disabled}
      notr
      onClick={play}
      {...rest}
    >
      <Flex>
        <img alt="play" width="48px" src={require('Assets/icons/play.svg')}/>
        <Text>client.results.buttons.play</Text>
      </Flex>
    </SButton>
  )
}

/* PropTypes */
PlayButton.propTypes = {}

PlayButton.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const Flex = styled.div`
  display: flex;
  align-items: center;
`

const SButton = styled(Button)`
  font-size:      18px !important;
  font-weight:    bold !important;
  letter-spacing: 3.94px !important;
  padding:        0 !important;
  padding-left:   6px !important;
  padding-right:  12px !important;
  text-transform: uppercase !important;
  width: 300px !important;

  span {
    margin-top: 4px;
  }

  img {
    margin-right: 14px;
  }
`
export default PlayButton
