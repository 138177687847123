/* Global imports */
import React, { useRef } from 'react'
import { Button, Grid, Segment } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import { add, format } from 'date-fns'

/* Local imports */
import { createClient } from 'Services/client'
import useSessionContext from 'Hooks/useSession'
import Text from 'Components/Text'
import Input from 'Components/Input'
import { getRefValue } from 'Utils'

const DATE_FORMAT = 'yyyy-MM-dd'

/* Component definition */
const ClientCreator = ({ onSuccess }) => {
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const customerNumberRef = useRef()
  const emailRef = useRef()
  const { user } = useSessionContext()
  const id = user ? user.professional_id : null

  const onSubmit = (e) => {
    e.preventDefault()

    const onError = (error) => {
      NotificationManager.error('There was an error when creating the client')
    }


    createClient({
      createdAt:       new Date(),
      customerNumber:  getRefValue(customerNumberRef),
      email:           getRefValue(emailRef),
      endDate:         format(add(new Date(), { days: 21 }), DATE_FORMAT),
      endDatePlus:     format(add(new Date(), { days: 21 }), DATE_FORMAT),
      firstName:       getRefValue(firstNameRef),
      lastName:        getRefValue(lastNameRef),
      professional_id: id,
      startDate:       format(new Date(), DATE_FORMAT),
      startDatePlus:   format(new Date(), DATE_FORMAT),
    })
      .then(onSuccess)
      .catch(onError)

  }
  return (
    <form onSubmit={onSubmit}>
      <Segment textAlign="center">
        <Text as="h1">client.editor.title</Text>
        <Grid padded>
          <Grid.Row>
            <Grid.Column width={6} textAlign="right">
              <Text as="h6">client.editor.firstName</Text>
            </Grid.Column>
            <Grid.Column width={8} textAlign="left">
              <Input
                placeholder='client.editor.firstName'
                ref={firstNameRef}
                required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width= {6} textAlign="right">
              <Text as="h6">client.editor.lastName</Text>
            </Grid.Column>
            <Grid.Column width={8} textAlign="left">
              <Input
                placeholder='client.editor.lastName'
                ref={lastNameRef}
                required
              />
            </Grid.Column>  
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width= {6} textAlign="right">
              <Text as="h6">client.editor.customerNumber</Text>
            </Grid.Column>
            <Grid.Column width={8} textAlign="left">
              <Input
                placeholder='client.editor.customerNumber'
                ref={customerNumberRef}
                required
              />
            </Grid.Column>  
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width= {6} textAlign="right">
              <Text as="h6">client.editor.email</Text>
            </Grid.Column>
            <Grid.Column width={8} textAlign="left">
              <Input
                placeholder='client.editor.email'
                ref={emailRef}
                type="email"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button primary type="submit"><Text>client.editor.save</Text></Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </form>
  )
}

/* PropTypes */
ClientCreator.propTypes = {}

ClientCreator.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

export default ClientCreator
