/* Global imports */
import React, { useRef, useState } from 'react'
// import { } from 'prop-types'
import { Button, Grid, Input } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

/* Local imports */
import * as ROUTES from 'Definitions/routes'
import { resetPassword } from 'Services/user'
import { getRefValue } from 'Utils'
import Text from 'Components/Text'

/* Component definition */
const ResetPassword = () => {
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const emailRef = useRef()

  const onSubmit = (e) => {
    e.preventDefault()

    const onError = ({ message }) => {
      setError(message)
    }

    const onSuccess = (data) => {
      setError('')
      setSuccessMessage(`Bitte überprüfen Sie Ihren Posteingang.`)
    }

    return resetPassword(getRefValue(emailRef))
      .then(onSuccess).catch(onError)
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid style={{marginTop: '100px'}} centered>
        <Text as="h2">login.resetPassword</Text>
        <Grid.Row>
          <Input placeholder="Email" ref={emailRef} />
        </Grid.Row>
        <Grid.Row>
          <Button primary type="submit"><Text>login.resetPassword</Text></Button>
        </Grid.Row>
        <Grid.Row>
          <NavLink to={ROUTES.LOGIN}>Login</NavLink>
        </Grid.Row>
      </Grid>
      <div>{ error }</div>
      <div>{ successMessage }</div>
    </form>
  )
}

/* PropTypes */
ResetPassword.propTypes = {}

ResetPassword.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

export default ResetPassword
