/* Global imports */
import React from 'react'
import styled from 'styled-components'
import { Grid, Label } from 'semantic-ui-react'

/* Local imports */
import PlayButton from 'Components/PlayButton'
import Text from 'Components/Text'
import Spacer from 'Components/Spacer'

/* Component definition */
const ListenAndSort = ({ exercise, results, isPlus }) => {

  return (
    <>
      <Spacer height="16px" />
      <Text notr>{exercise.description}</Text>
      <Spacer height="40px" />
      <SGridWrapper>
      <PlayButton exercise={exercise} step={1} isPlus={isPlus} />
      <Spacer height="40px" />
      <Grid>
        {
        exercise.labels.map((label, index) => {
          const result = getClientResult(results, index)
          const correctAnswer = exercise.steps[0].answer[index]

          return (
            <Grid.Row key={index}>
              {result ?
                <>
                  <Grid.Column style={{ alignSelf:' center'}} width={10}>
                    <Text notr>
                      {label}
                    </Text>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <SWrapper>
                    <SLabel
                      postion={!isAnswerCorrect(result, correctAnswer) ? 'absolute' : null }
                      left={!isAnswerCorrect(result, correctAnswer) ? '60px' : null }
                      bgcolor={getColor(result, correctAnswer)}
                    >
                      {result+1}
                    </SLabel>
                    {
                      !isAnswerCorrect(result, correctAnswer) &&
                      <SLabelRight
                        bgcolor={"#51B89D"}
                      >
                        <SDots>....</SDots>
                      {exercise.steps[0].answer[index] +1}
                      </SLabelRight>
                    }
                    </SWrapper>
                  </Grid.Column>
                </>
              :
                <p className='center'>Keine Antwort vorhanden</p>
              }
              
              <hr style={{ border: '0.5px solid #D8D8D8', width: '100%', margin:'16px 8px'}}/>
            </Grid.Row>

          )
        })
      }
      </Grid>
      </SGridWrapper>
      <Spacer height="44px" />
    </>
  )
}

/* PropTypes */
ListenAndSort.propTypes = {}

ListenAndSort.defaultProps = {}

/* Local utility functions */
const getClientResult = (results, index) => {
  if (results && results.step0) {
    return results.step0[index]
  }
  return
}

const getColor = (result, correctAnswer) => {

  if (!result && result !== 0) {
    return
  }

  if (result === correctAnswer) {
    return "#51B89D"
  }

  return "#E00069"
}

const isAnswerCorrect = (result, correctAnswer) => {

  if (!result && result !== 0) {
    return
  }

  if (result === correctAnswer) {
    return true
  }

  return false
}

/* Local Styled Components */
const SWrapper = styled(Grid.Row)`
  display: flex !important;
  justify-content: flex-end !important;
  position: relative !important;
`

const SLabel = styled(Label)`
  position: ${({ postion }) => postion} !important;
  right: ${({ left }) => left} !important;
  background-color: ${({ bgcolor }) => bgcolor} !important;
  height: 48px !important;
  width: 48px !important;
  color: #FFFFFF !important;
  font-size: 24px !important;
  border-radius: 100% !important;
  padding-top: 12px !important;
  padding-left: 17px !important;
  border: 2px solid white !important;
`

const SLabelRight = styled(Label)`
  background-color: ${({ bgcolor }) => bgcolor} !important;
  height: 48px !important;
  width: 110px !important;
  color: #FFFFFF !important;
  font-size: 24px !important;
  padding-top: 14px !important;
  padding-left: 80px !important;
  border-radius: 23px !important;
`

const SDots = styled.span`
  letter-spacing: -1px;
  position: absolute;
  right: 38px;
  bottom: 16px;
`
const SGridWrapper = styled.div`
@media (min-width: 786px) {
  max-width: 400px;
  margin: 0 auto;
}
`

export default ListenAndSort