export const bananaData = [
  {
    name: 'N1',
    data: [
      { frecuency: 0.25,  db: 10 },
      { frecuency: 0.375, db: 10 },
      { frecuency: 0.5,   db: 10 },
      { frecuency: 0.75,  db: 10 },
      { frecuency: 1,     db: 10 },
      { frecuency: 1.5,   db: 10 },
      { frecuency: 2,     db: 15 },
      { frecuency: 3,     db: 20 },
      { frecuency: 4,     db: 30 },
      { frecuency: 6,     db: 40},
      { frecuency: 8,     db: 50 },
    ],
    stroke:"#213FF9" 
  },
  {
    name: 'N2',
    data: [
      { frecuency: 0.25,  db: 20 },
      { frecuency: 0.375, db: 20 },
      { frecuency: 0.5,   db: 20 },
      { frecuency: 0.75,  db: 22.5 },
      { frecuency: 1,     db: 25},
      { frecuency: 1.5,   db: 30 },
      { frecuency: 2,     db: 35 },
      { frecuency: 3,     db: 40 },
      { frecuency: 4,     db: 45 },
      { frecuency: 6,     db: 50 },
      { frecuency: 8,     db: 55 },
    ],
    stroke:"#EC0BE5" 
  },
  {
    name: 'N3',
    data: [
      { frecuency: 0.25,  db: 35 },
      { frecuency: 0.375, db: 35 },
      { frecuency: 0.5,   db: 35 },
      { frecuency: 0.75,  db: 35 },
      { frecuency: 1,     db: 40 },
      { frecuency: 1.5,   db: 45 },
      { frecuency: 2,     db: 50 },
      { frecuency: 3,     db: 55 },
      { frecuency: 4,     db: 60 },
      { frecuency: 6,     db: 65 },
      { frecuency: 8,     db: 70 },
    ],
    stroke:"#0BECD1" 
  },
  {
    name: 'N4',
    data: [
      { frecuency: 0.25,  db: 55 },
      { frecuency: 0.375, db: 55 },
      { frecuency: 0.5,   db: 55 },
      { frecuency: 0.75,  db: 55 },
      { frecuency: 1,     db: 55 },
      { frecuency: 1.5,   db: 60 },
      { frecuency: 2,     db: 65 },
      { frecuency: 3,     db: 70 },
      { frecuency: 4,     db: 75 },
      { frecuency: 6,     db: 80 },
      { frecuency: 8,     db: 85 },
    ],
    stroke:"#E9EC0B" 
  },
  {
    name: 'N5',
    data: [
      { frecuency: 0.25,  db: 65 },
      { frecuency: 0.375, db: 67.5 },
      { frecuency: 0.5,   db: 70 },
      { frecuency: 0.75,  db: 72.5 },
      { frecuency: 1,     db: 75 },
      { frecuency: 1.5,   db: 80 },
      { frecuency: 2,     db: 80 },
      { frecuency: 3,     db: 80 },
      { frecuency: 4,     db: 80 },
      { frecuency: 6,     db: 80 },
      { frecuency: 8,     db: 80 },
    ],
    stroke:"#ECAF0B" 
  },
  {
    name: 'N6',
    data: [
      { frecuency: 0.25,  db: 75 },
      { frecuency: 0.375, db: 77.5 },
      { frecuency: 0.5,   db: 80 },
      { frecuency: 0.75,  db: 82.5 },
      { frecuency: 1,     db: 85 },
      { frecuency: 1.5,   db: 90 },
      { frecuency: 2,     db: 90 },
      { frecuency: 3,     db: 95 },
      { frecuency: 4,     db: 100 },
      { frecuency: 6,     db: 100 },
      { frecuency: 8,     db: 100 },
    ],
    stroke:"#EC6E0B" 
  },
  {
    name: 'N7',
    data: [
      { frecuency: 0.25,  db: 90 },
      { frecuency: 0.375, db: 92.5 },
      { frecuency: 0.5,   db: 95 },
      { frecuency: 0.75,  db: 100 },
      { frecuency: 1,     db: 105 },
      { frecuency: 1.5,   db: 105 },
      { frecuency: 2,     db: 105 },
      { frecuency: 3,     db: 105 },
      { frecuency: 4,     db: 105 },
      { frecuency: 6,     db: 105 },
      { frecuency: 8,     db: 105 },
    ],
    stroke:"#EC260B" 
  },
  {
    name: 'S1',
    data: [
      { frecuency: 0.25,  db: 10 },
      { frecuency: 0.375, db: 10 },
      { frecuency: 0.5,   db: 10 },
      { frecuency: 0.75,  db: 10 },
      { frecuency: 1,     db: 10 },
      { frecuency: 1.5,   db: 10 },
      { frecuency: 2,     db: 15 },
      { frecuency: 3,     db: 30 },
      { frecuency: 4,     db: 55 },
      { frecuency: 6,     db: 70 },
      { frecuency: 8,     db: 80 },
    ],
    stroke:"#967AA9" 
  },
  {
    name: 'S2',
    data: [
      { frecuency: 0.25,  db: 20 },
      { frecuency: 0.375, db: 20 },
      { frecuency: 0.5,   db: 20 },
      { frecuency: 0.75,  db: 22.5 },
      { frecuency: 1,     db: 25 },
      { frecuency: 1.5,   db: 35 },
      { frecuency: 2,     db: 55 },
      { frecuency: 3,     db: 75 },
      { frecuency: 4,     db: 95 },
      { frecuency: 6,     db: 95 },
      { frecuency: 8,     db: 95 },
    ],
    stroke:"#923E5A" 
  },
  {
    name: 'S3',
    data: [
      { frecuency: 0.25,  db: 30 },
      { frecuency: 0.375, db: 30 },
      { frecuency: 0.5,   db: 35 },
      { frecuency: 0.75,  db: 47.5 },
      { frecuency: 1,     db: 60 },
      { frecuency: 1.5,   db: 70 },
      { frecuency: 2,     db: 75 },
      { frecuency: 3,     db: 80 },
      { frecuency: 4,     db: 80 },
      { frecuency: 6,     db: 85 },
      { frecuency: 8,     db: 85 },
    ],
    stroke:"#0E0D0D"
  },
];


export const bananaCurves = [
  { key: 'N1', value: 'N1', text: 'N1' },
  { key: 'N2', value: 'N2', text: 'N2' },
  { key: 'N3', value: 'N3', text: 'N3' },
  { key: 'N4', value: 'N4', text: 'N4' },
  { key: 'N5', value: 'N5', text: 'N5' },
  { key: 'N6', value: 'N6', text: 'N6' },
  { key: 'N7', value: 'N7', text: 'N7' },
  { key: 'S1', value: 'S1', text: 'S1' },
  { key: 'S2', value: 'S2', text: 'S2' },
  { key: 'S3', value: 'S3', text: 'S3' },
]

export const earToDisplay = [
  { key: 'both', value: 'both', text: 'Beide Ohren' },
  { key: 'left', value: 'left', text: 'Linkes Ohr' },
  { key: 'right', value: 'right', text: 'Rechtes Ohr' },
]
