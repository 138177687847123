/* Global imports */
import React, { useEffect, useState } from 'react'
// import { } from 'prop-types'
import styled from 'styled-components'
import { Grid, Segment, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/* Local imports */
import { EXERCISE } from 'Definitions/routes'
import { getClient } from 'Services/client'
import { getCompletedStepCount, getStepCount } from 'Utils'
import Text from 'Components/Text'
import BackButton from 'Components/BackButton'

import exercisesStandard from '../Exercises'
import exercisesPlus from '../ExercisesPlus'
import optionalExercises from '../ExercisesPlus/OPTIONAL_EXERCISE'


/* Component definition */
const TrainingDay = ({ history, match, location }) => {
  const [client, setClient] = useState(null)
  const exercises = location.state && location.state.isPlus ? exercisesPlus : exercisesStandard
  const results = client ? location.state && location.state.isPlus ? client.answersPlus : client.answers : {}
  const { isPlus } = location.state;
  const { dayIndex, id } = match.params

  useEffect(() => {
    const subscription = getClient(id)
          .subscribe(doc => setClient(doc))

    return subscription.unsubscribe.bind(subscription)
  }, [id])

  return (
    <Grid padded>
      <BackButton history={history} />
      <Grid.Row>
        <Grid.Column width={16}>
          <Text as="h4" vars={{ day: parseInt(dayIndex) + 1 }}>trainingDay.title</Text>
        </Grid.Column>
        <Grid.Column width={16}>
          {
            exercises[dayIndex]
              .map((exercise, index) =>
                   <SSegment style={{backgroundColor: "#F2F9FB"}} key={index}>
                     <Link
                       to={{
                          pathname: EXERCISE(id, dayIndex, index),
                          state: { isPlus: location.state && location.state.isPlus }
                        }}
                       key={index}
                     >
                       <Grid.Column width={10}>
                         <Text as="p" style={{ color: 'black' }} vars={{ index: exercise.index +1 }}>trainingDay.exercise</Text>
                         <Text style={{ fontSize: '16px'}} notr> { exercise.title } </Text>
                       </Grid.Column>
                       <Grid.Column style={{textAlign: 'end'}} width={6}>
                         <Text style={{display: 'block'}} notr>
                           <Label circular color="blue"><SNumber>{ getCompletedStepCount(exercise, results) }</SNumber> </Label>
                           <span>&nbsp;</span>/ { getStepCount(exercise) } Aufgabenteilen bearbeitet
                         </Text>
                       </Grid.Column>
                     </Link>
                   </SSegment>
                  )
          }
          {
            isPlus && optionalExercises[dayIndex]
              .map((exercise, index) =>
                    <SSegment style={{backgroundColor: "#e7eaeb"}} key={index}>
                      <Link
                        to={{
                            pathname: EXERCISE(id, dayIndex, index),
                            state: { isPlus: location.state && location.state.isPlus,
                                      isOptional: true}
                          }}
                        key={index}
                      >
                        <Grid.Column width={10}>
                          <Text as="p" style={{ color: 'black' }} vars={{ index: exercise.index +1 }}>trainingDay.exercisePlus</Text>
                          <Text style={{ fontSize: '16px'}} notr> { exercise.title } </Text>
                        </Grid.Column>
                        <Grid.Column style={{textAlign: 'end'}} width={6}>
                          <Text style={{display: 'block'}} notr>
                            <Label circular color="blue"><SNumber>{ getCompletedStepCount(exercise, results) }</SNumber> </Label>
                            <span>&nbsp;</span>/ { getStepCount(exercise) } Aufgabenteilen bearbeitet
                          </Text>
                        </Grid.Column>
                      </Link>
                    </SSegment>
                    )
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )

}

/* PropTypes */
TrainingDay.propTypes = {}

TrainingDay.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SSegment = styled(Segment)`
  background-color: #F2F9FB;
  border:           none !important;
  box-shadow:       none !important;
  height:           auto !important;
`
const SNumber = styled.div`
    padding-top: 4px;
    padding-left: 2px;
`

export default TrainingDay
