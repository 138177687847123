/* Global imports */
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import styled from 'styled-components'

// import { } from 'prop-types'
import I18n from 'Common/i18n'
import { Button, Checkbox, Modal, Pagination, Table } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

/* Local imports */
import { ALL_CLIENTS_ANALYTICS , PROFESSIONAL_EDIT } from 'Definitions/routes'
import { getProfessionals, updateProfessional } from 'Services/professional'
import Text from 'Components/Text'
import Input from 'Components/Input'
import ProfessionalCreator from 'Components/ProfessionalCreator'
import Export from 'Components/Export'

const PAGINATION_NUMBER = 25

/* Component definition */
const SuperAdminOverview = () => {
  const [loading, setLoading] = useState(false)
  const [professionalList, setProfessionalList] = useState([])
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [datesModalOpen, setDatesModalOpen] = useState(false)
  const [hideInactive, setHideInactive] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [activeProfessional, setActiveProfessional] = useState()
  const [searchText, setSearchText] = useState()

  const start = (activePage * PAGINATION_NUMBER) - PAGINATION_NUMBER
  const final = activePage * PAGINATION_NUMBER

  const onToggleState = ({ active, id, ...rest }) => () => {
    setLoading(true)
    updateProfessional(id, {
      ...rest,
      active: !active,
    })
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const onSaveNew = () => {
    setCreateModalOpen(false)
    NotificationManager.success(I18n.t('professional.creator.notifications.success'))
  }

  const handlePaginationChange = ( e, { activePage: active }) => {
    setActivePage(active)
  }

  const findWithText = (professional) => {
    const searchExp = new RegExp(searchText, 'i')
  
    return searchExp.test(professional.companyName)
      || searchExp.test(professional.email)
  }

  useEffect(() => {
    const subscription = getProfessionals()
      .subscribe(setProfessionalList)

    return subscription.unsubscribe.bind(subscription)
  }, [])

  return (
    <div style={{ padding: '0px 8px'}}>
      <Modal
        closeIcon
        onClose={() => setCreateModalOpen(false)}
        open={createModalOpen}
        size="tiny"
        trigger={
          <div style={{ position: 'relative' }}>
            <SButton secondary onClick={() => setCreateModalOpen(true)}>
              <Text>superadmin.overview.buttons.new</Text>
            </SButton>
          </div>
          }
      >
        <ProfessionalCreator onSuccess={onSaveNew} />
      </Modal>
      <Modal
        closeIcon
        onClose={() => setDatesModalOpen(false)}
        size="tiny"
        open={datesModalOpen}
      >
        <Export
          professional={activeProfessional}
          professionalList={professionalList}
          onSuccess={() => setDatesModalOpen(false)} />
      </Modal>
      <Text as="h1" notr>Mitglied</Text>
      <Input
        placeholder="search.admin"
        onChange={(e, { value }) => setSearchText(new RegExp(value))}
        style={{width: '250px', fontSize: '14px', marginRight: '16px'}}
        type="text"
      />
      <NavLink to={ALL_CLIENTS_ANALYTICS}>
        <Button style={{ width: '200px'}}  color="green">
          <Text>professional.overview.buttons.analytics</Text>
        </Button>
      </NavLink>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Text>superadmin.overview.tableHeaders.professionalName</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>superadmin.overview.tableHeaders.email</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>superadmin.overview.tableHeaders.activeClients</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>superadmin.overview.tableHeaders.activeTrainingClients</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>superadmin.overview.tableHeaders.activeTrainingPlusClients</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>superadmin.overview.tableHeaders.totalClients</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Text>superadmin.overview.tableHeaders.active</Text>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                color="green"
                onClick={() => {
                    setActiveProfessional(undefined)
                    setDatesModalOpen(true)}}>
                  <Text notr>Gesamtexport</Text>
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            professionalList
              .filter(p => findWithText(p))
              .filter(p => hideInactive ? p.active : true)
              .slice(start, final)
              .map(
              (professional, index) =>
                  <Table.Row style={getRowStyle(professional)} key={`${professional.id} + ${index}`}>
                  <Table.Cell>
                    <NavLink to={PROFESSIONAL_EDIT(professional.id)}>{ professional.companyName }</NavLink>
                  </Table.Cell>
                  <Table.Cell>{ professional.email }</Table.Cell>
                  <Table.Cell>{ professional.activeClientCount }</Table.Cell>
                  <Table.Cell>{ professional.trainingActiveClientCount }</Table.Cell>
                  <Table.Cell>{ professional.trainingPlusActiveCount }</Table.Cell>
                  <Table.Cell>{ professional.totalClientCount }</Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      checked={professional.active}
                      disabled={loading}
                      onChange={onToggleState(professional)}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Button 
                      primary
                      onClick={() => {
                        setActiveProfessional(professional)
                        setDatesModalOpen(true)
                      }}>
                      <Text>superadmin.overview.tableHeaders.export</Text>
                    </Button>
                  </Table.Cell>
                </Table.Row>
            )
          }

          {
            professionalList.length === 0 &&
              <Table.Row>
                <Table.Cell colSpan={4} textAlign="center" width={16}>
                  <Text>superadmin.overview.noResults</Text>
                </Table.Cell>
              </Table.Row>
          }
        </Table.Body>
      </Table>
      <SPaginationWrapper>
        <Checkbox
          checked={hideInactive}
          onChange={(e, { checked }) => { setHideInactive(checked) }}
          label="Inaktive Kunden ausblenden"
        />
        <SPagination
          activePage={activePage}
          boundaryRange={0}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={handlePaginationChange}
          totalPages={Math.ceil(professionalList.length / PAGINATION_NUMBER)}
        />
      </SPaginationWrapper>
    </div>
  )
}

/* PropTypes */
SuperAdminOverview.propTypes = {}

SuperAdminOverview.defaultProps = {}

/* Local utility functions */
const getRowStyle = ({ active }) => {
  return {
    backgroundColor: active ? '' : '#fff3f3'
  }
}

/* Local Styled Components */
const SButton = styled(Button)`
  position: absolute;
  right: 10px;
}
`

const SPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}
`

const SPagination = styled(Pagination)`
  justify-content: center !important;
  width: unset !important;
}
`

export default SuperAdminOverview
