const fields = [
  {
    label: 'Firmenname *',
    name: 'companyName',
    disabled(loading, { id }) {
      return loading || !!id
    },
    required: true,
  },
  {
    label: 'Name',
    name: 'name',
  },
  {
    label: 'Vorname',
    name: 'firstName',
  },
  {
    label: 'Straße und Hausnummer',
    name: 'address',
  },
  {
    label: 'Postleitzahl',
    name: 'zipCode',
  },
  {
    label: 'Stadt',
    name: 'city',
  },
  {
    label: 'Telefon',
    name: 'phone',
  },
  {
    label: 'Fax',
    name: 'fax',
  },
  {
    label: 'Kontakt Email',
    name: 'contactEmail',
  },
  {
    label: 'GLN *',
    name: 'glnNumber',
    disabled(loading, { id }) {
      return loading || !!id
    },
    required: true,
  },
  {
    label: 'Email *',
    name: 'email',
    disabled(loading, { id }) {
      return loading || !!id
    },
    required: true,
  },
]

export default fields
