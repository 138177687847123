/* Global imports */
import React, { useEffect, useRef, useState } from 'react'
import { LineChart, Line, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts'
import { object } from 'prop-types'
import { Button, Checkbox, Grid, Image, Input, Select, Table } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import { assign, getPathOr, setPath, isNumber, isEmpty } from 'crocks'
import { path } from 'ramda'
import { add, format } from 'date-fns'

/* Local imports */
import { PROFESSIONAL_OVERVIEW } from 'Definitions/routes'
import { getClient, updateClient, generateAccessPlusCode } from 'Services/client'
import { getProfessional } from 'Services/professional'
import Text from 'Components/Text'
import { addWeeks } from 'Utils/client'
import useSessionContext from 'Hooks/useSession'
import useWindowSize from 'Hooks/useWindowSize'
import { bananaData, bananaCurves, earToDisplay} from 'Utils/banana'

const initialState = {
  accessCode:          '',
  accessCodePlus:          '',
  active:              false,
  bananaType:          "",
  bananaTypeLeft:      "",
  earToDisplay:        earToDisplay[0].text,
  customerNumber:      '',
  email:               '',
  endDate:             '',
  endDatePlus:             '',
  entryLevel:          '',
  firstName:           '',
  lastActivity:        '',
  lastName:            '',
  postcode:            '',
  results: [
    {
      frecuency: 0.25,
      db: ""
    },
    {
      frecuency: 0.375,
      db: ""
    },
    {
      frecuency: 0.5,
      db: ""
    },
    {
      frecuency: 0.75,
      db: ""
    },
    {
      frecuency: 1,
      db: ""
    },
    {
      frecuency: 1.5,
      db: ""
    },
    {
      frecuency: 2,
      db: ""
    },
    {
      frecuency: 3,
      db: ""
    },
    {
      frecuency: 4,
      db: ""
    },
    {
      frecuency: 6,
      db: ""
    },
    {
      frecuency: 8,
      db: ""
    },
  ],
  resultsLeft: [
    {
      frecuency: 0.25,
      db: ""
    },
    {
      frecuency: 0.375,
      db: ""
    },
    {
      frecuency: 0.5,
      db: ""
    },
    {
      frecuency: 0.75,
      db: ""
    },
    {
      frecuency: 1,
      db: ""
    },
    {
      frecuency: 1.5,
      db: ""
    },
    {
      frecuency: 2,
      db: ""
    },
    {
      frecuency: 3,
      db: ""
    },
    {
      frecuency: 4,
      db: ""
    },
    {
      frecuency: 6,
      db: ""
    },
    {
      frecuency: 8,
      db: ""
    },
  ],
  startDate: '',
  startDatePlus: '',
  startDay:  1,
  startDayPlus:  1,
  street:    '',
  tel:       '',
  trainingActive: false,
  trainingPlusActive: false
}

const roundDB = (number) => {
  const delta = number % 5

  if (delta < 2.5) {
    return number - delta
  }

  return number - delta + 5
}

const DATE_FORMAT = 'yyyy-MM-dd'

/* Component definition */
const ClientEditor = ({ history, match }) => {
  const [client, setClient] = useState(initialState)
  const [loading, setLoading] = useState(true)
  const [ logo, setLogo] = useState({ downloadUrl: ''})
  const { id } = match.params
  const { user } = useSessionContext()
  const windowSize = useWindowSize()
  const professional_id = user ? user.professional_id : null

  const error = useRef('')

  const generateAccessPlus = () => {
    setLoading(true)
    const data = {
      id: client.id,
      endDatePlus: format(add(new Date(), { days: 21 }), DATE_FORMAT),
      startDatePlus: format(new Date(), DATE_FORMAT),
    }
    const generateFunction = generateAccessPlusCode()
    
    generateFunction(data)
      .then(() => setLoading(false))
      .catch((er) => {console.log(er)})
  }

  const onFieldChange = (key, valueKey = 'value') => (e, { [valueKey]: value }) => {
    if (key === 'startDate') {
      const endDate = addWeeks(value, 3)

      return setClient({
        ...client,
        startDate: value,
        endDate,
      })
    }

    if (key === 'startDatePlus') {
      const endDatePlus = addWeeks(value, 3)

      return setClient({
        ...client,
        startDatePlus: value,
        endDatePlus,
      })
    }

    setClient(setPath(
      key.split('.')
        .map(x => isNumber(parseInt(x)) ? parseInt(x): x ),
      value,
      client
    ))
  }

  const onToggleState = (field, { trainingActive, trainingPlusActive, id, ...rest }) => () => {
    setLoading(true)
    const newActiveState = {
      trainingActive: field === 'trainingActive' ? !trainingActive : !!trainingActive,
      trainingPlusActive: field === 'trainingPlusActive' ? !trainingPlusActive : !!trainingPlusActive
    }

    updateClient(id, {
      ...rest,
      active: newActiveState.trainingActive || newActiveState.trainingPlusActive,
      trainingActive: newActiveState.trainingActive,
      trainingPlusActive: newActiveState.trainingPlusActive
    })
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const setDBValue = (key) => (e) => {
    const valuePath = key.split('.')
          .map(x => isNumber(parseInt(x)) ? parseInt(x): x )

    setClient(setPath(
      valuePath,
      roundDB(path(valuePath, client)),
      client
    ))
  }

  const validate = () => {

    if (isEmpty(client.firstName) || isEmpty(client.lastName)) {
      error.current = 'Erforderliches Feld (Vorname und Nachname)'
      return
    }

    if (isEmpty(client.startDate) || isEmpty(client.endDate) || isEmpty(client.startDatePlus) || isEmpty(client.endDatePlus)) {
      error.current = 'Erforderliches Feld (Startdatum und Enddatum)' 
      return
    }
    error.current = ''
    return true
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (validate()) {
      updateClient(id, client)
        .then(() => {
          history.push(PROFESSIONAL_OVERVIEW())
          NotificationManager.success('Einstellungen wurden erfolgreich aktualisiert')
        })
    } else {
      NotificationManager.error(error.current) 
    }
  }

  useEffect(() => {
    const subscription = getClient(id)
          .subscribe(doc => {
            const data = fillTemplate(doc)

            setClient(data)
            setLoading(false)
          })

    return subscription.unsubscribe.bind(subscription)
  }, [id])

  useEffect(() => {
    if(professional_id) {
      const subscription = getProfessional(professional_id)
            .subscribe(doc => {
              setLogo({
                downloadUrl: getPathOr('', ['settings', 'logo', 'downloadUrl'], doc)
              })

              setLoading(false)
            })
      return subscription.unsubscribe.bind(subscription)
    }
  }, [professional_id])

  return (
    <form>
      <Text className='notPrintable' as="h1">client.detail.title</Text>
        <Grid padded>
          <Grid.Row>
            <Grid.Column>
              <div className='printable logo-print'>
                <Image alt="Logo" src={logo.downloadUrl} rounded/>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.customerNumber</Text>
            </Grid.Column>
            <Grid.Column mobile={10} tablet={10} computer={10} textAlign="left">
              <Input
                disabled={true}
                onChange={onFieldChange('customerNumber')}
                value={client.customerNumber}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.id</Text>
            </Grid.Column>
            <Grid.Column mobile={10} tablet={10} computer={10} textAlign="left">
              <Input
                disabled={true}
                onChange={onFieldChange('id')}
                value={client.id}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.firstName</Text>
            </Grid.Column>
            <Grid.Column>
              <Input
                disabled={loading}
                onChange={onFieldChange('firstName')}
                value={client.firstName}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.lastName</Text>
            </Grid.Column>
            <Grid.Column>
              <Input
                disabled={loading}
                onChange={onFieldChange('lastName')}
                value={client.lastName}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.street</Text>
            </Grid.Column>
            <Grid.Column>
              <Input
                disabled={loading}
                onChange={onFieldChange('street')}
                value={client.street}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.postcode</Text>
            </Grid.Column>
            <Grid.Column>
              <Input
                disabled={loading}
                onChange={onFieldChange('postcode')}
                value={client.postcode}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.city</Text>
            </Grid.Column>
            <Grid.Column>
              <Input
                disabled={loading}
                onChange={onFieldChange('city')}
                value={client.city}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.tel</Text>
            </Grid.Column>
            <Grid.Column>
              <Input
                disabled={loading}
                onChange={onFieldChange('tel')}
                value={client.tel}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.email</Text>
            </Grid.Column>
            <Grid.Column>
              <Input
                disabled={loading}
                onChange={onFieldChange('email')}
                type="email"
                value={client.email}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={12} tablet={12} computer={8}>
              <Grid padded='vertically'>
                <Grid.Row>
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.accessCode</Text>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={10}>
                    <input
                      className="access-code"
                      style={{
                        padding: '10px 12px',
                        fontFamily: 'serif',
                        fontSize: '20px',
                        color: 'rgba(126, 124, 124, 0.82)',
                        maxWidth: '200px',
                        marginRight: '15px'
                      }}
                      disabled={true}
                      onChange={onFieldChange('accessCode')}
                      value={client.accessCode}
                    />
                    <Button
                      className="notPrintable"
                      content={<Text>client.editor.print</Text>}
                      onClick={() => window.print()}
                      type="button"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="notPrintable">
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.trainingActive</Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      checked={client.trainingActive}
                      disabled={loading}
                      onChange={onToggleState('trainingActive', client)}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="notPrintable">
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.startDay</Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      disabled={loading}
                      onChange={onFieldChange('startDay')}
                      value={client.startDay}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="notPrintable">
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.startDate</Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      disabled={loading}
                      onChange={onFieldChange('startDate')}
                      type="date"
                      value={client.startDate}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="notPrintable">
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.endDate</Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      disabled={loading}
                      onChange={onFieldChange('endDate')}
                      type="date"
                      value={client.endDate}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={12} computer={8}>
              <Grid padded='vertically'>
                <Grid.Row>
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.accessCode</Text>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={10}>
                    <input
                      className="access-code"
                      style={{
                        padding: '10px 12px',
                        fontFamily: 'serif',
                        fontSize: '20px',
                        color: 'rgba(126, 124, 124, 0.82)',
                        maxWidth: '200px',
                        marginRight: '15px'
                      }}
                      disabled={true}
                      onChange={onFieldChange('accessCodePlus')}
                      value={client.accessCodePlus}
                    />
                    {client.accessCodePlus ? 
                      <Button
                        className="notPrintable"
                        content={<Text>client.editor.print</Text>}
                        onClick={() => window.print()}
                        type="button"
                      /> 
                    : 
                      <Button
                        primary
                        className="notPrintable"
                        disabled={loading}
                        //style={{backgroundColor: "green"}}
                        content={<Text notr>Zugangscode generieren</Text>}
                        onClick={() => generateAccessPlus()}
                        type="button"
                      /> 
                    }
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="notPrintable">
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.trainingPlusActive</Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      checked={client.trainingPlusActive}
                      disabled={loading}
                      onChange={onToggleState('trainingPlusActive', client)}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="notPrintable">
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.startDay</Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      disabled={loading}
                      onChange={onFieldChange('startDayPlus')}
                      value={client.startDayPlus}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="notPrintable">
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.startDate</Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      disabled={loading}
                      onChange={onFieldChange('startDatePlus')}
                      type="date"
                      value={client.startDatePlus}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="notPrintable">
                  <Grid.Column mobile={8} tablet={8} computer={6}>
                    <Text as="h6">client.editor.endDate</Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      disabled={loading}
                      onChange={onFieldChange('endDatePlus')}
                      type="date"
                      value={client.endDatePlus}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          
          <Text as="h6" className="notPrintable" notr>Hörverlust</Text>
          <Grid.Row className="notPrintable">
            <Grid.Column width={4}>
              <Text as="p" className="notPrintable">client.editor.rightEar</Text>
              <Table celled unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Text>client.editor.results.tableHeaders.frecuency</Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text>client.editor.results.tableHeaders.dB</Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    client && client.results.map((result, index) =>
                                                 <Table.Row key={index}>
                                                   <Table.Cell>{ result.frecuency}</Table.Cell>
                                                   <Table.Cell>
                                                     <Input
                                                       step={5}
                                                       disabled={loading}
                                                       onChange={onFieldChange(`results.${parseInt(index)}.db`)}
                                                       onBlur={setDBValue(`results.${parseInt(index)}.db`)}
                                                       value={result.db}
                                                     />
                                                   </Table.Cell>
                                                 </Table.Row>
                                                )
                  }
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column width={3} style={{margin: '20px 80px 0px 180px'}}>
              <Button
                primary
                type="button"
                onClick={() => {
                  setClient(setPath(
                    ['resultsLeft'],
                    client.results,
                    client
                  ))
                }}
              >
                 Inhalte übertragen
              </Button>
            </Grid.Column>
            <Grid.Column width={4}>
              <Text as="p" className="notPrintable">client.editor.leftEar</Text>
              <Table celled unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Text>client.editor.results.tableHeaders.frecuency</Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text>client.editor.results.tableHeaders.dB</Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    client && client.resultsLeft.map((result, index) =>
                                                 <Table.Row key={index}>
                                                   <Table.Cell>{ result.frecuency}</Table.Cell>
                                                   <Table.Cell>
                                                     <Input
                                                       step={5}
                                                       disabled={loading}
                                                       onChange={onFieldChange(`resultsLeft.${parseInt(index)}.db`)}
                                                       onBlur={setDBValue(`resultsLeft.${parseInt(index)}.db`)}
                                                       value={result.db}
                                                     />
                                                   </Table.Cell>
                                                 </Table.Row>
                                                )
                  }
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="notPrintable">
          <Grid.Column mobile={16} tablet={8} computer={6}>
              <LineChart
                data={client.results}
                height={windowSize.width < 500 ? 200 : 400}
                margin={{ left: 0 , top: 8 }}
                width={windowSize.width < 500 ? 300 : 500}
               >
                {bananaData.map((curve, index) => (
                  <Line
                    dataKey="db"
                    data={curve.data}
                    name={curve.name}
                    key={index}
                    stroke={curve.stroke}
                    strokeWidth={4}
                  />
                ))}
                <Line
                  type="monotone"
                  dataKey="db"
                  stroke="#61EC0B"
                  strokeWidth={6}
                />
                                {
                  windowSize.width > 500 &&
                  <Legend align="right" height={18}/>
                }
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                <XAxis 
                  allowDuplicatedCategory={false}
                  label={{ value: 'f/kHz', position: 'insideTopRight', offset: -1}}
                  dataKey="frecuency"
                  orientation="top"
                />
                <YAxis 
                  dataKey="db"
                  reversed
                />

                <Tooltip />
              </LineChart>
              <span style={{ paddingLeft: '0px'}}>L/dB</span>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={6}>
              <LineChart
                data={client.resultsLeft}
                height={windowSize.width < 500 ? 200 : 400}
                margin={{ left: 0 , top: 8 }}
                width={windowSize.width < 500 ? 300 : 500}
               >
                {bananaData.map((curve, index) => (
                  <Line
                    dataKey="db"
                    data={curve.data}
                    name={curve.name}
                    key={index}
                    stroke={curve.stroke}
                    strokeWidth={4}
                  />
                ))}
                <Line
                  type="monotone"
                  dataKey="db"
                  stroke="#61EC0B"
                  strokeWidth={6}
                />
                                {
                  windowSize.width > 500 &&
                  <Legend align="right" height={18}/>
                }
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                <XAxis 
                  allowDuplicatedCategory={false}
                  label={{ value: 'f/kHz', position: 'insideTopRight', offset: -1}}
                  dataKey="frecuency"
                  orientation="top"
                />
                <YAxis 
                  dataKey="db"
                  reversed
                />

                <Tooltip />
              </LineChart>
              <span style={{ paddingLeft: '0px'}}>L/dB</span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column width={6}>
              <div style={{display: 'flex'}}>
                <Text as="h6">client.editor.bananaRight</Text>
                <Select
                  className="curve-selector"
                  options={bananaCurves}
                  onChange={onFieldChange('bananaType')}
                  value={client.bananaType}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={6}>
              <div style={{display: 'flex'}}>
                <Text as="h6">client.editor.bananaLeft</Text>
                <Select
                  className="curve-selector"
                  options={bananaCurves}
                  onChange={onFieldChange('bananaTypeLeft')}
                  value={client.bananaTypeLeft}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable" style={{ marginTop: '32px'}} >
            <Grid.Column mobile={6} tablet={6} computer={4}>
              <Text as="h6">client.editor.earToDisplay</Text>
            </Grid.Column>
            <Grid.Column>
              <Select
                className="curve-selector"
                options={earToDisplay}
                onChange={onFieldChange('earToDisplay')}
                value={client.earToDisplay}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column mobile={6} tablet={6} computer={3}>
              <Text as="h6">client.editor.active</Text>
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                checked={client.active}
                disabled
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="notPrintable">
            <Grid.Column>
              <Button
                secondary
                onClick={() => history.push(PROFESSIONAL_OVERVIEW())}>
                <Text>client.editor.cancel</Text>
              </Button>
              <Button primary onClick={onSubmit}>
                <Text>client.editor.save</Text>
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className='printable'>
              <Text>client.editor.nameOfApp</Text>
              Hörtraining - Erhältlich im AppStore und im Google Play Store
            </div>
          </Grid.Row>
        </Grid>
    </form>
  )
}

/* PropTypes */
ClientEditor.propTypes = {
history: object,
match:   object,
}

ClientEditor.defaultProps = {}

/* Local utility functions */
const fillTemplate = x => assign(x, initialState)

/* Local Styled Components */

export default ClientEditor
