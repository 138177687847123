/* Global imports */
import React from 'react'
import { object } from 'prop-types'
import { Grid, Label } from 'semantic-ui-react'
import styled from 'styled-components'

/* Local imports */
import { listenAndTypeShape } from 'Definitions/shapes'
import Text from 'Components/Text'
import PlayButton from 'Components/PlayButton'
import Spacer from 'Components/Spacer'
import { processDate, processNumber, processString } from 'Utils'
import { eqBy } from 'ramda'

/* Component definition */
const ListenAndType = ({ exercise, results, isPlus, optional }) => {
  return (
    <div>
      {
        exercise.stages
          .map((stage, stageIndex) =>
            <div key={stageIndex}>
              <Spacer height="16px" />
              <Text notr>{exercise.description[stageIndex]}</Text>
              <Spacer height="48px" />
              <Grid>
                {
                  stage.map((step, stepIndex) =>
                  {
                    const result = getClientResult(stageIndex, results, stepIndex)
                    const answerCorrect = isAnswerCorrect(step.answer, result, exercise.inputType, exercise.multi)

                    return (
                      <Grid.Row key={stepIndex}>
                        <Grid.Column width={16}>
                          <SWrapper>
                            <Text style={{ transform: 'translate(-8px)' }} as="p" >{answerCorrect ? 'exercise.results.correctAnswer' : 'exercise.results.wrongAnswer'}</Text>
                            <Spacer height="16px" />
                            {result ?
                              <SLabel
                                bgcolor={getColor(result, step.answer, exercise.inputType, exercise.multi)}
                              >
                                {step.prefix}
                                <span style={{ fontWeight: 'bold' }}>
                                  {` ${formatClientResults(result, exercise, step)} `} 
                                </span>
                                {step.sufix} 
                              </SLabel>
                            :
                              <p className='center'>Keine Antwort vorhanden</p>
                            }
                            <SPlayButton exercise={exercise} stage={stageIndex} step={stepIndex} isPlus={isPlus} optional={optional}/>
                          </SWrapper>
                        </Grid.Column>
                      </Grid.Row>
                    )
                  }

                  )
                }
              </Grid>
              <Spacer height="64px" />
            </div>
          )
      }
    </div>
  )
}

/* PropTypes */
ListenAndType.propTypes = {
  exercise: listenAndTypeShape,
  results: object,
}

ListenAndType.defaultProps = {}

/* Local utility functions */
const isAnswerCorrect = (correctAnswer, userAnswer, inputType, multi) => {

  if (!userAnswer) {
    return
  }

  const comparator = inputType === 'number'
    ? processNumber
    : inputType === 'date'
      ? processDate
      : processString

    if (multi) {
      return arrayEquals(userAnswer, correctAnswer) 
    }

  return eqBy(comparator, userAnswer, correctAnswer)
}

const formatClientResults = (result, exercise, step) => {

  if (!result) {
    return
  }

  if (exercise.multi) {
    return `${result[0]} ${step.separator} ${result[1]}`
  }

  const date = result.split('/').slice(0, 3).join('/')
  const time = result.split('/').slice(3).join(':')

  return `${date} ${time}`
}

const getClientResult = (currentStage, results, index) => {
  if (!results) {
    return
  }


  if (results[`stage${currentStage}`] && results[`stage${currentStage}`][`step${index}`]) {
    return results[`stage${currentStage}`][`step${index}`]
  }
  return
}

const getColor = (result, correctAnswer, inputType, multi) => {
  const comparator = inputType === 'number'
    ? processNumber
    : inputType === 'date'
      ? processDate
      : processString

  if (!result) {
    return
  }

  if (multi) {
    if (arrayEquals(result, correctAnswer)) return "rgba(81,184,157,0.45)"
    else return "rgba(224,0,105,0.31)"
  }

  if (eqBy(comparator, result, correctAnswer)) {
    return "rgba(81,184,157,0.45)"
  }

  return "rgba(224,0,105,0.31)"
}

function arrayEquals(a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

/* Local Styled Components */
const SPlayButton = styled(PlayButton)`
  margin: 16px 0px !important;
  margin-bottom: 32px !important;
  width: 100% !important;
`
const SWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0px 16px;

  @media (min-width: 786px) {
    max-width: 400px;
    margin: 0 auto;
  }
`

const SLabel = styled(Label)`
  color: #000000 !important;
  background-color: ${({ bgcolor }) => bgcolor} !important;
  border: 1px solid lightgrey !important;
  margin: 4px !important;
  whiteSpace: normal !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 1.5px !important;
  line-height: 16px !important;
  padding: 16px !important;
  text-align: center !important;
  `

export default ListenAndType
