/* Global imports */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Image, Menu } from 'semantic-ui-react'

/* Local imports */
import useSessionContext from 'Hooks/useSession'
import { LOGIN, PROFESSIONAL_SETTINGS } from 'Definitions/routes'
import Text from 'Components/Text'

const menuItems = [
  {
    title: 'professional.settings.title',
    to: PROFESSIONAL_SETTINGS(),
  },
]


/* Component definition */
const Header = ({ history }) => {
  const { logout, user } = useSessionContext()
  const [menuOpen, setMenuOpen] = useState(false)

  const role = user && user.role

  const logoUrl = require('Assets/images/logo.svg')
  const burguerMenu = menuOpen ? require('Assets/icons/cross.svg') : require('Assets/icons/menu.svg')

  const onLogout = () => {
    logout()
      .then(() => history.push(LOGIN()))
  }

  const onItemClick = (path) => {
    setMenuOpen(false)
    history.push(path)
  }

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState)
  }

  return (
      <Menu className="menu" secondary>
        <Menu.Item
          className="notPrintable"
          content={<Image alt="logo" src={logoUrl} size="small" />}
          onClick={() => history.push(LOGIN())}
        />
        <SText as="h1"  className="notPrintable" notr>Zentralverwaltung</SText>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        {
          menuOpen &&
            <SMenu vertical>
            {
              role === 'professional' &&
              menuItems.map(item =>
                <Menu.Item
                className="notPrintable"
                name={item.to}
                key={item.to}
                >
                  <Text as="h6" onClick={() => onItemClick(item.to)}>
                    {item.title}
                  </Text>
                </Menu.Item>
              )
            }
            <Menu.Item
              className="notPrintable"
              name="Abmelden"
              >
              <Text as="h6" onClick={onLogout}>
                mainMenu.logout
              </Text>
            </Menu.Item>
          </SMenu>
        }

      <Menu.Item
        className="notPrintable"
        content={<SBurguerIcon alt="menu" src={burguerMenu} />}
        onClick={() => toggleMenu()}
      />
      </div>
      </Menu>
  )
}
/* PropTypes */
Header.propTypes = {}
Header.defaultProps = {}

/* Local utility functions */

/* Styles */
const SBurguerIcon = styled(Image)`
height: 48px !important;
width:  48px !important;
`

const SMenu = styled(Menu)`
   cursor: pointer;
  position: absolute;
  top: 52px;
  right: 16px;
  z-index: 1;
`

const SText = styled(Text)`
 text-align: center;
 width: 100%;
@media (max-width: 500px){
  display: none !important;
} 
`

export default Header