import { getPath, map, option, pipe } from 'crocks'
import { join, last, replace, split } from 'ramda'
import { isObject } from 'crocks/predicates'
import { or } from 'crocks/logic'

export const getRefValue = pipe(
  getPath(['current', 'inputRef', 'current', 'value']),
  option(''),
)

export const getRefFile = pipe(
  getPath(['current', 'inputRef', 'current', 'files', 0]),
  option(''),
)

export const getExtension = pipe(
  split('.'),
  last,
)

const processLetter = x => {
  const letters = [
    ['ss', 'ß'],
    ['a', 'ä'],
    ['o', 'ö'],
    ['u', 'ü'],
  ]

  const foundLetterGroup = letters.find(letterGroup => letterGroup.includes(x))

  return foundLetterGroup ? foundLetterGroup[0] : x
}

export  const processString = (input) => {
  const string = input.split('').map(processLetter).join('')

  const regex = new RegExp(/[A-Za-z0-9]+/, 'g')

  if(string.match(regex)) {
    return string.match(regex).join('').toLowerCase()
  }

  return string
}

// processNumber :: String -> Number
export const processNumber = pipe(
  replace(',', '.'),
  x => parseFloat(x),
)

// processDate :: String -> String
// Returns a date without leading 0's
export const processDate = pipe(
  split('/'),
  map(x => parseInt(x)),
  join('/')
)

export const hasRole = (role) => (user) => {
  if (role === 'admin') {
    return user.role ? false : role !== 'professional'
  }
  if (role === 'professional') {
    return role === user.role
  }
}

export const setAudioFile = (exercise, currentStep, currentStage) => {
  if (exercise.stages && currentStage === 1){
    const stepsNumbers = getStepCount(exercise)
    const step = currentStep + (stepsNumbers / exercise.stages.length)  + 1

    return `${exercise.index + 1}-${step}.mp3`
  }

  return `${exercise.index + 1}-${currentStep + 1}.mp3`
}

export const getStepCount = ({ stages, steps }) => {
  if (stages) {
    return stages.reduce((total, current) => {
      return total + current.length
    }, 0)
  }

  return steps.length
}

const isStep = key => /^step.*/.test(key)

const isStage = key => /^stage.*/.test(key)

export const getCompletedStepCount = ({ index, optional }, results = {}) => {
  const res = optional ? results[`exercise-optionalExercise-${index}`] : results[`exercise${index}`]

  if (!res) {
    return 0
  }

  return Object.keys(res)
    .filter(or(isStep, isStage))
    .reduce((total, key) => {
      if (isObject(res[key])) {
        return total + Object.keys(res[key]).length
      }

      return total + 1
    }, 0)
}