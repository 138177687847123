export const addWeeks= (date, numberOfWeeks) => {
  if (date) {
    const startDate = new Date(date)
    const futureDate = new Date(startDate.setDate(startDate.getDate() + (numberOfWeeks * 7) ))
      .toISOString()
      .substr(0, 10)
  
      return futureDate
  }
}

export const getClientFullName = (client) => {
  return client.lastName ? `${client.firstName} ${client.lastName}` : client.firstName 
}

export const parseDate = (date) => {
  return date.split('-')
    .map(x => parseInt(x))
}