/* Global imports */
import React, { useEffect, useRef, useState } from 'react'
// import { } from 'prop-types'
import { Button, Grid, Image, Input } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

/* Local imports */
import * as ROUTES from 'Definitions/routes'
import Text from 'Components/Text'
import useSessionContext from 'Hooks/useSession'
import { getRefValue } from 'Utils'

const navigateAfterLogin = (role, history) => {
  if (role === 'professional') {
    return history.push(ROUTES.PROFESSIONAL_OVERVIEW())
  }
  return history.push(ROUTES.SUPER_ADMIN_OVERVIEW()) 
}

/* Component definition */
const Login = ({ history }) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, user } = useSessionContext()
  const logoUrl = require('Assets/images/logo.svg')

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    const onError = ({ message }) => {
      setError(message)
      setLoading(false)
    }

    const onSuccess = async () => {
      setError('')
      setLoading(false)
    }

    return login(getRefValue(emailRef), getRefValue(passwordRef))
      .then(onSuccess).catch(onError)
  }

  useEffect(() => {
    if (user) {
      navigateAfterLogin(user.role, history)
      }
  }, [history, user])
 
  return (
    <form onSubmit={onSubmit}>
      <Grid style={{marginTop: '100px'}} centered>
        <Image alt="logo" src={logoUrl} size="medium" />
        <Grid.Row >
          <Input className="login-input" disabled={loading} placeholder="Email" ref={emailRef} />
        </Grid.Row>
        <Grid.Row >
          <Input  className="login-input" disabled={loading} placeholder="Passwort" ref={passwordRef} type="password" />
        </Grid.Row>
        <Grid.Row >
          <Button disabled={loading} primary type="submit">Anmelden</Button>
        </Grid.Row>
          <div style={{color: 'red'} }>{ error }</div>
        <Grid.Row >
        </Grid.Row>
        <NavLink to={ROUTES.RESET_PASSWORD()}><Text>login.resetPassword</Text></NavLink>
      </Grid>
    </form>
  )
}

/* PropTypes */
Login.propTypes = {}

Login.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

export default Login
