/* Global imports */
import React from 'react'
import { number, string } from 'prop-types'

/* Local imports */

/* Component definition */
const ColorBox = ({ color, size }) => {
  return (
    <div style={{
      backgroundColor: color,
      height:          size,
      width:           size,
    }}>
    </div>
  )
}

/* PropTypes */
ColorBox.propTypes = {
  color: string,
  size:  number,
}

ColorBox.defaultProps = {
  color: 'red',
  size:  20,
}

/* Local utility functions */

/* Local Styled Components */

export default ColorBox
