/* Global imports */
import React, { useEffect, useState } from 'react'
// import { } from 'prop-types'
import { Progress, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { map, pipe, reduce } from 'ramda'

/* Local imports */
import { getStepCount, getCompletedStepCount } from 'Utils'
import { getClient } from 'Services/client'
import { REVIEW_EXERCISE, TRAINING_DAY } from 'Definitions/routes'
import Text from 'Components/Text'
import Spacer from 'Components/Spacer'
import TrainingMenu from 'Components/TrainingMenu'

import trainingDays from '../Exercises'
import trainingDaysPlus from '../ExercisesPlus'

const daysWithStaticExercises = [4, 7, 10, 14]

/* Component definition */
const TrainingOverview = ({ match, location }) => {
  const [client, setClient] = useState(null)
  const results = client ? location.state && location.state.isPlus ? client.answersPlus : client.answers : {}
  const startDay = client && client.startDay ? client.startDay : 1
  const availableDays = location.state && location.state.isPlus ? trainingDaysPlus.slice(startDay - 1) : trainingDays.slice(startDay - 1)
  const { id } = match.params
  const isPlus = location.state && location.state.isPlus
  const getProgressForDay = getDailyProgress(results, availableDays)
  
  useEffect(() => {
    const subscription = getClient(id)
                          .subscribe(doc => setClient(doc))

    return subscription.unsubscribe.bind(subscription)
  }, [id])
  
  return (
    <>
      <TrainingMenu className="notPrintable" clientId={id} location={location} />
      <div style={{ padding: '0px 8px'}}>
        <Text as="h1">client.results.overview.title</Text>
        {
          availableDays
            .map((exercises, index) => (
              <React.Fragment key={index}>
                <Segment>
                  <Link
                    style={styles.trainingDay}
                    to={{
                      pathname: TRAINING_DAY(id, exercises[0].day - 1),
                      state: { isPlus: isPlus}
                    }}
                  >
                    <div style={styles.title}>
                      <Text as="h2" vars={{ day: exercises[0].day}}>client.results.overview.dayHeader</Text>
                    </div>
                    <div style={styles.percentage}>
                      <Progress
                        autoSuccess
                        percent={getProgressForDay(index)}
                        size="small"
                        indicating
                      />
                      { `${getProgressForDay(index).toFixed(0)} %` }
                    </div>
                  </Link>
                </Segment>

                {
                  (hasReviewExercise(exercises[0].day) && !(location.state && location.state.isPlus)) &&

                  <Segment>
                    <Link
                      to={REVIEW_EXERCISE(id, exercises[0].day)}
                    >
                      <Text as="h2">client.results.overview.reviewExercise</Text>
                    </Link>
                  </Segment>
                }

              </React.Fragment>
            ))
        }
        </div>
        <Spacer height="64px"/>
    </>
  )
}

/* PropTypes */
TrainingOverview.propTypes = {}

TrainingOverview.defaultProps = {}

/* Local utility functions */
const getDailyProgress = (results, days) => {
  const countAnsweredSteps = day => day.reduce(
    (total, exercise) => total + getCompletedStepCount(exercise, results),
    0
  )

  const getProgress = map(countAnsweredSteps)

  const exerciseCount = pipe(
    map(reduce((t, exercise) => {
      return t + getStepCount(exercise)
    }, 0)),
  )(days)

  return index => getProgress(days)[index] * 100 / exerciseCount[index]
}

const hasReviewExercise = (day) => daysWithStaticExercises.includes(day)

/* Local Styled Components */
const styles = {
  trainingDay: {
    height:  '100px',
  },
  title: {
  },
  percentage: {
    height:    '60px',
    textAlign: 'center',
  },
}

export default TrainingOverview
