/* Global imports */
import React from 'react'
import styled from 'styled-components'
import { Grid, Label } from 'semantic-ui-react'

/* Local imports */
import Text from 'Components/Text'
import PlayButton from 'Components/PlayButton'
import Spacer from 'Components/Spacer'

/* Component definition */
const ListenAndPickAccentedWord = ({ exercise, results, isPlus }) => {

  return (
    exercise.stages
      .map((stage, stageIndex) =>
          <div key={stageIndex}>
            <Spacer height="16px" />
            <Text notr>{exercise.description[stageIndex]}</Text>
            <Spacer height="48px" />
            <Grid>
                {
                  stage
                    .map((step, stepIndex) =>
                      <Grid.Row key={stepIndex}>
                        <Grid.Column width={16}>
                          {
                            renderResults(step.choices, getClientResult(stageIndex, results, stepIndex), step.answer, exercise, stageIndex, stepIndex, isPlus)
                          }
                        </Grid.Column>
                      </Grid.Row>
                    )
                }
            </Grid>
            <Spacer height="64px" />
          </div>
      )
  )
}

/* PropTypes */
ListenAndPickAccentedWord.propTypes = {}

ListenAndPickAccentedWord.defaultProps = {}

/* Local utility functions */
const areEqual = (correctAnswer, userAnswer) => {

  if (!userAnswer) {
    return
  }

  return correctAnswer.length === userAnswer.length
    && userAnswer.reduce((total, current) => total && correctAnswer.includes(current), true)
}

const getClientResult = (currentStage, results, stepIndex) => {
  if (results[`stage${currentStage}`] && results[`stage${currentStage}`][`step${stepIndex}`]) {
    return results[`stage${currentStage}`][`step${stepIndex}`]
  }

  return
}

const getColor = (result, correctAnswer, buttonValue) => {

  if (!result) {
    return
  }

  if (areEqual(correctAnswer, result) && correctAnswer.includes(buttonValue)) {
    return "rgba(81,184,157,0.45)"
  }

  if (!areEqual(correctAnswer, result) && result.includes(buttonValue)) {
    return "rgba(224,0,105,0.31)"
  }

  return "transparent"
}

const renderResults = (options, result, correctAnswer, exercise, stageIndex, stepIndex, isPlus) => {
  const answerCorrect = areEqual(correctAnswer, result)

  return (
    <SWrapper>
      <Text style={{ transform: 'translate(-108px)' }} as="p" >{answerCorrect ? 'exercise.results.correctAnswer' : 'exercise.results.wrongAnswer'}</Text>
      <Spacer height="16px" />
    {
      options.map((option, index) => {
        const answerCorrectColor = getColor(result, correctAnswer, index)

        return (
          <SLabel
            bgcolor={answerCorrectColor}
            key={index}
          >
            {option}
          </SLabel>
        )
      })
    }
    <SPlayButton exercise={exercise}  stage={stageIndex} step={stepIndex} isPlus={isPlus} />
    </SWrapper>

  )
}
/* Local Styled Components */

const SPlayButton = styled(PlayButton)`
  margin: 16px 0px !important;
  margin-bottom: 32px !important;
  width: 100% !important;

`
const SWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 0px 16px;

  @media (min-width: 786px) {
    max-width: 400px;
    margin: 0 auto;
  }
`

const SLabel = styled(Label)`
  color: #000000 !important;
  background-color: ${({ bgcolor }) => bgcolor} !important;
  border: 1px solid lightgrey !important;
  margin: 4px !important;
  whiteSpace: normal !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 1.5px !important;
  line-height: 16px !important;
  padding: 16px !important;
  width: fit-content !important;
`
export default ListenAndPickAccentedWord
