export default [
  [
    require('./LISTEN_AND_TYPE/Exercise1.json'),
    require('./LISTEN_AND_SORT/Exercise2.json'),
    require('./LISTEN_AND_SELECT_TONE/Exercise3.json'),
    require('./LISTEN_AND_SELECT_TONE/Exercise4.json'),

  ],
  [
    require('./LISTEN_AND_SELECT_TONE/Exercise5.json'),
    require('./LISTEN_AND_SELECT_TONE/Exercise6.json'),
    require('./LISTEN_AND_PICK/Exercise7.json'),
    require('./LISTEN_AND_PICK_ACCENTED_WORD/Exercise8.json'),
  ],
  [
    require('./LISTEN_AND_TYPE/Exercise9.json'),
    require('./LISTEN_AND_PICK/Exercise10.json'),
    require('./LISTEN_AND_SELECT_TONE/Exercise11.json'),
  ],
  [
    require('./LISTEN_AND_PICK_ACCENTED_WORD/Exercise12.json'),
    require('./LISTEN_AND_TYPE/Exercise13.json'),
    require('./LISTEN_AND_TYPE/Exercise14.json'),
    require('./LISTEN_AND_SELECT_TONE/Exercise15.json'),
  ],
  [
    require('./LISTEN_AND_TYPE/Exercise16.json'),
    require('./LISTEN_AND_SORT/Exercise17.json'),
    require('./LISTEN_AND_SORT/Exercise18.json'),
    require('./LISTEN_AND_PICK/Exercise19.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise20.json'),
    require('./LISTEN_AND_PICK/Exercise21.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise22.json'),
    require('./LISTEN_AND_PICK/Exercise23.json'),
    require('./LISTEN_AND_SELECT_TONE/Exercise24.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise25.json'),
    require('./LISTEN_AND_TYPE/Exercise26.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise27.json'),
    require('./LISTEN_AND_PICK/Exercise28.json'),
    require('./LISTEN_AND_PICK/Exercise29.json'),
    require('./LISTEN_AND_SELECT_TONE/Exercise30.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise31.json'),
    require('./LISTEN_AND_PICK/Exercise32.json'),
    require('./LISTEN_AND_TYPE/Exercise33.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise34.json'),
    require('./LISTEN_AND_PICK_ACCENTED_WORD/Exercise35.json'),
    require('./LISTEN_AND_PICK/Exercise36.json'),
  ],
  [
    require('./LISTEN_AND_SELECT_TONE/Exercise37.json'),
    require('./LISTEN_AND_TYPE/Exercise38.json'),
    require('./LISTEN_AND_SORT/Exercise39.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise40.json'),
    require('./LISTEN_AND_PICK/Exercise41.json'),
    require('./LISTEN_AND_PICK/Exercise42.json'),
  ],
  [
    require('./LISTEN_AND_PICK/Exercise43.json'),
    require('./LISTEN_AND_PICK/Exercise44.json'),
  ],
]
