/* Global imports */
import React from 'react'
import styled from 'styled-components'
import { isInteger } from 'crocks/predicates'
import { Label, Grid } from 'semantic-ui-react'

// import { } from 'prop-types'

/* Local imports */
import Text from 'Components/Text'
import PlayButton from 'Components/PlayButton'
import Spacer from 'Components/Spacer'

/* Component definition */
const ListenAndPick = ({ exercise, results, isPlus }) => {

  return (
    <>
      <Spacer height="16px" />
      <Text notr>{exercise.description}</Text>
      <Spacer height="48px" />
      <Grid>
        {
          exercise.steps.map((step, index) =>
            <Grid.Row key={index}>
              <Grid.Column width={16}>
                {
                  exercise.oneStep ?
                    renderOneStepResults(exercise, step, getClientResult(results, index), step.answer, index, isPlus)
                    :
                    renderResults(setChoices(exercise, step), getClientResult(results, index), step.answer, exercise, index, isPlus)
                }
              </Grid.Column>
            </Grid.Row>
          )
        }
      </Grid>
      <Spacer height="64px" />
    </>
  )
}

/* PropTypes */
ListenAndPick.propTypes = {}

ListenAndPick.defaultProps = {}

/* Local utility functions */
const setChoices = (exercise, step) => {
  return exercise.choices ? exercise.choices : step.choices
}

const areEqual = (correctAnswer, userAnswer) => {

  if (!userAnswer) {
    return
  }

  return correctAnswer.length === userAnswer.length
    && userAnswer.reduce((total, current) => total && correctAnswer.includes(current), true)
}

const getClientResult = (results, index) => {
  if (results && results[`step${index}`]) {
    return results[`step${index}`]
  }
  return
}

const getColor = (result, correctAnswer, buttonValue) => {

  if (!result) {
    return
  }

  if (isInteger(result) && isInteger(correctAnswer)) {
    return result === correctAnswer && result === buttonValue ? "rgba(81,184,157,0.45)" :
      result !== correctAnswer && result === buttonValue ? "rgba(224,0,105,0.31)" : "transparent"
  }

  if (areEqual(correctAnswer, result) && correctAnswer.includes(buttonValue)) {
    return "rgba(81,184,157,0.45)"
  }

  if (!areEqual(correctAnswer, result) && result.includes(buttonValue)) {
    return "rgba(224,0,105,0.31)"
  }

  return "transparent"
}

const renderResults = (options, result, correctAnswer, exercise, stepIndex, isPlus) => {
  const answerCorrect = areEqual(correctAnswer, result)

  return (
    <SWrapper>
      <Text style={{ transform: 'translate(-8px)' }} as="p" >{answerCorrect ? 'exercise.results.correctAnswer' : 'exercise.results.wrongAnswer'}</Text>
      <Spacer height="16px" />
      {
        options.map((option, index) => {
          const answerCorrect = getColor(result, correctAnswer, index)

          return (
            <SLabel
              key={index}
              bgcolor={answerCorrect}
            >
              {option}
            </SLabel>
          )
        })
      }
      <SPlayButton exercise={exercise} step={stepIndex} isPlus={isPlus} />
    </SWrapper>
  )
}

const renderOneStepResults = (exercise, step, result, correctAnswer, stepIndex, isPlus) => {
  if (!result) {
    return
  }

  return (
      <SWrapper>
        <SPlayButton exercise={exercise} step={stepIndex} isPlus={isPlus} />
        {
          step.options.map((option, index) =>

            <Grid.Row key={index}>
              {
                option.description &&
                <Text style={{ margin: '16px 0px' }} as="p" notr>{option.description}</Text>
              }
                <SWrapper>
              {
                option.choices.map((choice, i) =>
                  <SLabel
                    key={i}
                    bgcolor={getColor(result[index], correctAnswer[index], i)}
                  >
                    {choice}
                  </SLabel>
                )
              }
              </SWrapper>
            </Grid.Row>
          )
        }
      </SWrapper>
  )
}

/* Local Styled Components */

const SPlayButton = styled(PlayButton)`
  margin: 16px 0px !important;
  margin-bottom: 32px !important;
  width: 100% !important;

`
const SWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0px 16px;

  @media (min-width: 786px) {
    max-width: 400px;
    margin: 0 auto;
  }
`

const SLabel = styled(Label)`
  color: #000000 !important;
  background-color: ${({ bgcolor }) => bgcolor} !important;
  border: 1px solid lightgrey !important;
  margin: 4px 0px !important;
  whiteSpace: normal !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 1.5px !important;
  line-height: 16px !important;
  padding: 16px !important;
  padding-left: 40px !important;
`

export default ListenAndPick