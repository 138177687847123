export default [
  [
    require('../LISTEN_AND_TYPE/OptionalA.json'),
    require('../LISTEN_AND_TYPE/OptionalB.json'),
  ],
  [
  ],
  [
    require('../LISTEN_AND_SORT/OptionalC.json'),
  ],
  [
  ],
  [
  ],
  [
    require('../LISTEN_AND_TYPE/OptionalD.json'),
    require('../LISTEN_AND_TYPE/OptionalE.json'),
  ],
  [
  ],
  [
  ],
  [
    require('../LISTEN_AND_SORT/OptionalF.json'),
  ],
  [
  ],
  [
    require('../LISTEN_AND_SORT/OptionalG.json'),
  ],
  [
  ],
  [
  ],
  [
    require('../LISTEN_AND_PICK/OptionalH.json'),
    require('../LISTEN_AND_PICK/OptionalI.json'),
  ]
]