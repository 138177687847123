import { db, storage } from '../common/database'
import { collection as getCollection, doc } from 'rxfire/firestore'
import { map } from 'rxjs/operators'
import { assign } from 'crocks'
import mime from 'mime-types'

import { getExtension } from 'Utils'

const collection = db.collection('professionals')

export function getProfessionals() {
  return getCollection(collection.orderBy('createdAt', 'desc'))
    .pipe(map(docs => docs.map(doc => assign(doc.data(), { id: doc.id }))))
}

export function getProfessional(id) {
  return doc(collection.doc(id))
    .pipe(map(doc => assign(doc.data(), { id: doc.id })))
}

export function createProfessional(data) {
  return collection
    .add(assign({ createdAt: new Date() }, data))
}

export function deleteProfessional(id) {
  return collection
    .doc(id)
    .delete()
}

export function updateProfessional(id, data) {
  return collection
    .doc(id)
    .update(data)
}

export function uploadSettingsLogo(professionalId, logo) {
  const contentType = mime.lookup(logo.name)

  return storage.child(`professional/${professionalId}/logo.${getExtension(logo.name)}`)
    .put(logo, {
      contentType,
    })
    .then(snap => snap.ref.getDownloadURL())
    .then(downloadUrl => {
      return {
        contentType,
        createdAt:   new Date(),
        downloadUrl,
      }
    })
}

export function uploadProfessionalImage(professionalId, image) {
  const contentType = mime.lookup(image.name)

  return storage.child(`professional/${professionalId}/image.${getExtension(image.name)}`)
    .put(image, {
      contentType,
    })
    .then(snap => snap.ref.getDownloadURL())
    .then(downloadUrl => {
      return {
        contentType,
        createdAt:   new Date(),
        downloadUrl,
      }
    })
}
