/* Global imports */
import React, { useEffect, useState } from 'react'
import { object } from 'prop-types'
import { Button, Checkbox, Grid, Input } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import { assign, identity } from 'crocks'

/* Local imports */
import I18n from 'Common/i18n'
import { SUPER_ADMIN_OVERVIEW } from 'Definitions/routes'
import { getProfessional, updateProfessional } from 'Services/professional'
import Text from 'Components/Text'
import fields from './fields'

export const initialState = {
  active:         false,
  address:        '',
  city:           '',
  companyName:    '',
  contactEmail:  '',
  email:          '',
  fax:            '',
  firstName:      '',
  glnNumber:      '',
  name:           '',
  phone:          '',
  zipCode:        '',
}

/* Component definition */
const ProfessionalEditor = ({ history, match }) => {
  const [loading, setLoading] = useState(true)
  const [professional, setProfessional] = useState(initialState)
  const { id } = match.params

  const onFieldChange = (key, valueKey = 'value') => (e, { [valueKey]: value }) => {
    setProfessional({
      ...professional,
      [key]: value,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    updateProfessional(id, professional)
      .then(() => {
        history.push(SUPER_ADMIN_OVERVIEW())
        NotificationManager.success(I18n.t('professional.creator.notifications.success'))
      })
  }

  useEffect(() => {
    const subscription = getProfessional(id)
          .subscribe(doc => {
            const data = fillTemplate(doc)

            setProfessional(data)
            setLoading(false)
          })

    return subscription.unsubscribe.bind(subscription)
  }, [id])

  return (
      <form onSubmit={onSubmit}>
      <Text as="h1">professional.detail.title</Text>
        <Grid padded>
          {
            fields.map(renderField(loading, onFieldChange, professional))
          }
          <Grid.Row>
            <Grid.Column>
              <Text as="h3">client.editor.active</Text>
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                checked={professional.active}
                disabled={loading}
                onChange={onFieldChange('active', 'checked')}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                secondary
                onClick={() => history.push(SUPER_ADMIN_OVERVIEW())}>
                <Text>professional.cancel</Text>
              </Button>
              <Button primary type="submit">
                <Text>professional.creator.save</Text>
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </form>
  )
}

/* PropTypes */
ProfessionalEditor.propTypes = {
  history: object,
  match:   object,
}

ProfessionalEditor.defaultProps = {}

/* Local utility functions */
const fillTemplate = x => assign(x, initialState)

export const renderField = (loading, onFieldChange, professional) => ({ as: As = Input, disabled = identity, label, name, required }) => {
  return <Grid.Row key={name}>
               <Grid.Column mobile={6} tablet={6} computer={6}>
                 <Text as="h6" notr>{ label }</Text>
               </Grid.Column>
               <Grid.Column>
                 <As
                   disabled={disabled(loading, professional)}
                   onChange={onFieldChange(name)}
                   value={professional[name]}
                   required={required}
                 />
               </Grid.Column>
             </Grid.Row>
}


/* Local Styled Components */

export default ProfessionalEditor
