/* Global imports */
import React, { useEffect, useState } from 'react'
import {  object } from 'prop-types'
import { keys } from 'ramda'

/* Local imports */
import { EXERCISE, TRAINING_OVERVIEW } from 'Definitions/routes'
import { getClient } from 'Services/client'

import Text from 'Components/Text'
import BackButton from 'Components/BackButton'

//import EverydayLife from 'Components/EverydayLife'
import ListenAndType from 'Components/ListenAndType'
import ListenAndSort from 'Components/ListenAndSort'
import ListenAndSelectTone from 'Components/ListenAndSelectTone'
import ListenAndPick from 'Components/ListenAndPick'
import ListenAndPickAccentedWord from 'Components/ListenAndPickAccentedWord'
import ListenAndPickImages from 'Components/ListenAndPickImages'
import ListenAndMarkGaps from 'Components/ListendAndMarkGaps'
import ListenAndTypeAndPick from 'Components/ListenAndTypeAndPick'
import ReviewExercise from 'Components/ReviewExercise'

import exercisesStandard from '../Exercises'
import exercisesPlus from '../ExercisesPlus'
import optionalExercises from '../ExercisesPlus/OPTIONAL_EXERCISE'

/* Component definition */
const Exercise = ({ history, match, location }) => {
  const [ results, setResults] = useState(null)
  const isPlus = location.state && location.state.isPlus
  const isOptional = location.state && location.state.isOptional
  const exercises = isPlus ? (isOptional ? optionalExercises : exercisesPlus) : exercisesStandard
  const { exerciseIndex, dayIndex, id } = match.params
  const exerciseNumber =  exercises[dayIndex][exerciseIndex].index

  const onNext = () => {
    const nextExerciseIndex = parseInt(exerciseIndex) + 1

    if (nextExerciseIndex < exercises[dayIndex].length) {
      setResults(null)
      return history.push(EXERCISE(id, dayIndex, nextExerciseIndex))
    }

    return history.push(TRAINING_OVERVIEW(id))
  }

  useEffect(() => {
    const subscription = getClient(id)
          .subscribe(doc => {
            const results = getExerciseAnswers(isPlus ? doc.answersPlus : doc.answers, exerciseNumber, isOptional)
            setResults(results)
          })
    return subscription.unsubscribe.bind(subscription)
  }, [exerciseIndex, exerciseNumber, id, location.state])

  const currentExercise = exercises[dayIndex][exerciseIndex]

  return (
    <div style={{ maxWidth: '1300px', margin: '0 auto'}}>
      <BackButton history={history} />
      <header>
        <Text as="h2" vars={{ number: currentExercise.index + 1 }}>
          {isOptional ? 'client.results.titleOptional' : 'client.results.title'}
        </Text>
        <Text as="h1" notr>{ currentExercise.title }</Text>
      </header>
     { renderExercise(currentExercise, onNext, results, isPlus, isOptional) }
    </div>
  )
}

/* PropTypes */
Exercise.propTypes = {
  match:      object,
}

Exercise.defaultProps = {
}

/* Local utility functions */
const renderExercise = (exercise, onNext, results, isPlus, isOptional) => {
  if(!results) {
    return
  }
  switch (exercise.type) {
    case 'LISTEN_AND_TYPE':
      return <ListenAndType exercise={exercise} onNext={onNext} results={results} isPlus={isPlus} optional={isOptional}/>
    case 'LISTEN_AND_SORT':
      return <ListenAndSort exercise={exercise} onNext={onNext} results={results} isPlus={isPlus}/>
    case 'LISTEN_AND_SELECT_TONE':
      return <ListenAndSelectTone exercise={exercise} onNext={onNext} results={results} isPlus={isPlus}/>
    case 'LISTEN_AND_PICK':
      return <ListenAndPick exercise={exercise} onNext={onNext} results={results} isPlus={isPlus}/>
    case 'LISTEN_AND_PICK_ACCENTED_WORD':
      return <ListenAndPickAccentedWord exercise={exercise} onNext={onNext} results={results} isPlus={isPlus}/>
    case 'LISTEN_AND_PICK_IMAGE':
      return <ListenAndPickImages exercise={exercise} onNext={onNext} results={results} isPlus={isPlus}/>
    case 'REVIEW_EXERCISE':
      return <ReviewExercise exercise={exercise} onNext={onNext} />
    case 'LISTEN_AND_MARK_GAPS':
      return <ListenAndMarkGaps exercise={exercise} onNext={onNext} results={results} isPlus={isPlus}/>
    case 'LISTEN_AND_TYPE_AND_PICK':
      return <ListenAndTypeAndPick exercise={exercise} onNext={onNext} results={results} isPlus={isPlus}/>
  default:
    throw Error(`Unrecognized Type ${exercise.type}`)
  }
}

const getExerciseAnswers = (answers, exerciseIndex, optional) => {
  const answeredExercises = Object.keys(answers)
  return answeredExercises.reduce((acc, exercise) => {
    const condition = optional ? exercise === `exercise-optionalExercise-${exerciseIndex}` : exercise === `exercise${exerciseIndex}`
    if (condition) {
      return answers[exercise]
    }
    return acc
  }, {})
}
/* Local Styled Components */

export default Exercise
