import React from 'react'
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import * as ROUTES from 'Definitions/routes'

import Login from 'Screens/Login'
import ResetPassword from 'Screens/ResetPassword'
import SuperAdminOverview from 'Screens/SuperAdminOverview'
import ProfessionalEditor from 'Screens/ProfessionalEditor/index.jsx'
import ProfessionalOverview from 'Screens/ProfessionalOverview'
import ClientEditor from 'Screens/ClientEditor'
import ClientAnalytics from 'Screens/ClientAnalytics'
import AllClientAnalytics from 'Screens/AllClientAnalytics'
import TrainingOverview from 'Screens/TrainingOverview'
import TrainingDay from 'Screens/TrainingDay'
import Exercise from 'Screens/Exercise'
import ProfessionalSettings from 'Screens/ProfessionalSettings'
import ProtectedRoute from 'Components/ProtectedRoute'
import ReviewExercise from 'Components/ReviewExercise'
import { hasRole } from 'Utils'

export default () => {
  return (
    <Router>
      <Switch>
        <Route component={Login} path={ROUTES.LOGIN.PATH} />
        <Route component={ResetPassword} path={ROUTES.RESET_PASSWORD.PATH} />
        <ProtectedRoute
          component={SuperAdminOverview}
          condition={hasRole('admin')}
          path={ROUTES.SUPER_ADMIN_OVERVIEW.PATH}
        />
        <ProtectedRoute
          component={AllClientAnalytics}
          condition={hasRole('admin')}
          path={ROUTES.ALL_CLIENTS_ANALYTICS.PATH}
        />
        <ProtectedRoute
          component={ProfessionalSettings}
          condition={hasRole('professional')}
          exact
          path={ROUTES.PROFESSIONAL_SETTINGS.PATH}
        />
        <ProtectedRoute
          component={ProfessionalEditor}
          condition={hasRole('admin')}
          exact
          path={ROUTES.PROFESSIONAL_EDIT.PATH}
        />
        <ProtectedRoute
          component={ProfessionalOverview}
          condition={hasRole('professional')}
          path={ROUTES.PROFESSIONAL_OVERVIEW.PATH}
        />
        <ProtectedRoute
          component={ClientEditor}
          condition={hasRole('professional')}
          path={ROUTES.CLIENT_EDIT.PATH}
        />
        <ProtectedRoute
          component={ClientAnalytics}
          condition={hasRole('professional')}
          path={ROUTES.CLIENT_ANALYTICS.PATH}
        />
        <ProtectedRoute
          component={Exercise}
          condition={hasRole('professional')}
          path={ROUTES.EXERCISE.PATH}
        />
        <ProtectedRoute
          component={TrainingDay}
          condition={hasRole('professional')}
          path={ROUTES.TRAINING_DAY.PATH}
        />
        <ProtectedRoute
          component={TrainingOverview}
          condition={hasRole('professional')}
          path={ROUTES.TRAINING_OVERVIEW.PATH}
        />
        <ProtectedRoute
          component={ReviewExercise} path={ROUTES.REVIEW_EXERCISE.PATH}
          condition={hasRole('professional')}
        />
        <Redirect to={ROUTES.LOGIN.PATH}/>
      </Switch>
    </Router>
  )
}
