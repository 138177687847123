/* Global imports */
import React from 'react'
import styled from 'styled-components'

/* Local imports */

/* Component definition */
const Spacer = ({ height }) => {

  return (
    <SSpacer height={height}/>
  )
}

/* PropTypes */
Spacer.propTypes = {}

Spacer.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SSpacer = styled.div`
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '100%' }) => height};
`
export default Spacer