/* Global imports */
import React from 'react'
import { func, string } from 'prop-types'
import { TwitterPicker } from 'react-color'

/* Local imports */

/* Component definition */
const ColorPicker = ({ color, onChange }) => {
  return (
    <TwitterPicker
      color={color}
      onChangeComplete={({ hex }) => onChange(null, { value: hex })}
      triangle="hide"
    />
  )
}

/* PropTypes */
ColorPicker.propTypes = {
  color:    string,
  onChange: func,
}

ColorPicker.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

export default ColorPicker
