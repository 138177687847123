import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// Your web app's Firebase configuration
// Use config-dev for deve environment configuration
import firebaseConfig from './config'

// Initialize Firebase
const connection = firebase.initializeApp(firebaseConfig)

export const db = connection.firestore()
export const auth = connection.auth()
export const storage = connection.storage().ref()

export default connection
