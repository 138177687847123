// Taken from:
// https://usehooks.com/useWindowSize/
import { useCallback, useEffect, useState } from 'react'

export default function useWindowSize() {

  const isClient = typeof window === 'object'

  const getSize = useCallback(() => ({
    height: isClient ? window.innerHeight : undefined,
    width:  isClient ? window.innerWidth :  undefined,
  }), [isClient])

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [getSize, isClient])

  return windowSize
}
