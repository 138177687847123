
export const allowedMIMETypes = ['image/giff', 'image/jpeg', 'image/png']

export const isFileImage = (file) => {
  if (allowedMIMETypes.includes(file.type)){
    return true
  }

  return false
}
