import { auth } from '../common/database'
import { authState } from 'rxfire/auth'
import { filter, switchMap } from 'rxjs/operators'

export function onStateChange(fn) {
  const getUserData = async user => {
    const { claims } = await user.getIdTokenResult()

    const { email, name, professional_id, role, user_id } = claims

    return { email, name, professional_id, role, uid: user_id }
  }

  return authState(auth)
    .pipe(filter(u => !!u))
    .pipe(switchMap(getUserData))
}

export function login(email, password) {
  return auth
    .signInWithEmailAndPassword(email, password)
}

export function logout() {
  return auth
    .signOut()
}

export function resetPassword(email) {
  return auth.sendPasswordResetEmail(email)
}