/* Global imports */
import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { number, oneOfType, string } from 'prop-types'

/* Local imports */
import { CLIENT_ANALYTICS, TRAINING_OVERVIEW, PROFESSIONAL_OVERVIEW } from 'Definitions/routes'
import Button from 'Components/Button'
import BackButton from 'Components/BackButton'

/* Component definition */
const TrainingMenu = ({ className, clientId, history, location }) => {
  const pathname = history.location && history.location.pathname

  return (
    <div className={className} style={{position: 'relative', display: 'flex', cursor: 'pointer'}}>
      <BackButton history={history} route={PROFESSIONAL_OVERVIEW} />
      <SButton
        active={pathname.includes('analytics')}
        onClick={() => history.push({pathname: CLIENT_ANALYTICS(clientId), state: {isPlus: location.state && location.state.isPlus }})}
      >
        Kompakte Ansicht
      </SButton>
      <SButton
        active={pathname.includes('overview')}
        onClick={() => history.push({pathname: TRAINING_OVERVIEW(clientId), state: {isPlus: location.state && location.state.isPlus }})}
      >
        Trainingsübersicht
      </SButton>
    <SPrintButton primary onClick={() => window.print()}>
      trainingMenu.print
    </SPrintButton>
    </div>
  )
}

/* PropTypes */
TrainingMenu.propTypes = {
  className: string,
  clientId:  oneOfType([number, string]),
}

TrainingMenu.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SButton = styled.button`
  background-color: transparent !important;
  border: none;
  border-bottom: ${({ active }) =>  active && '1px solid #0082BA'};
  color: #0082BA;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 32px;
  letter-spacing: 1.5px;
  line-height: 21px;
  margin: 0;
  padding:0;
  padding-top: 4px;
  margin-right: 16px;
  text-transform: uppercase;
`

const SPrintButton = styled(Button)`
  position: absolute;
  top: 0px;
  right: 10px;
}
`

export default withRouter(TrainingMenu)
