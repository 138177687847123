/* Global imports */
import React, { useEffect, useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { pathOr } from 'ramda'
import { getPathOr } from 'crocks'

/* Local imports */
import useSessionContext from 'Hooks/useSession'
import { getClient } from 'Services/client'
import { getProfessional } from 'Services/professional'
import { getStepCount } from 'Utils'
import Text from 'Components/Text'


import exercisesStandard from '../Exercises'
import exercisesPlus from '../ExercisesPlus'

import reviewButtons from 'Definitions/reviewButtons'
import TrainingMenu from 'Components/TrainingMenu'

/* Component definition */
const ClientAnalytics = ({ match , location}) => {
  const { id } = match.params
  const [loading, setLoading] = useState(true)
  const exercises = location.state && location.state.isPlus ? exercisesPlus : exercisesStandard

  const [exerciseAnalytics, setExerciseAnalytics] = useState([])
  const [reviewAnalytics, setReviewAnalytics] = useState([])
  const [ logo, setLogo] = useState({ downloadUrl: ''})
  const { user } = useSessionContext()
  const professional_id = user ? user.professional_id : null

  useEffect(() => {
    const subscription = getClient(id)
          .subscribe(user => {
            setExerciseAnalytics(getAnalyticsByDay(location.state && location.state.isPlus ? user.answersPlus : user.answers , user.comments, exercises))
            setReviewAnalytics(getReviewAnalyticsByDay(user.answers))
          })

    return subscription.unsubscribe.bind(subscription)
  }, [exercises, id, location.state])

  useEffect(() => {
    if(professional_id) {
      const subscription = getProfessional(professional_id)
            .subscribe(doc => {
              setLogo({
                downloadUrl: getPathOr('', ['settings', 'logo', 'downloadUrl'], doc)
              })

              setLoading(false)
            })
      return subscription.unsubscribe.bind(subscription)
    }
  }, [professional_id])

  const getAnalyticsByDay = (answers, comments, exercises) => {
    return exercises.map((dayExercises, index) => {
      return {
        correctSteps: getCorrectCount(answers, dayExercises.map(x => x.index)),
        dayNumber:    index + 1,
        totalSteps:   dayExercises.reduce((total, current) => total + getStepCount(current), 0),
        comment:      comments && comments[`day${index + 1}`]
      }
    })
  }

  return (
    <>
      <TrainingMenu className="notPrintable" clientId={id} location={location} />
      <Grid padded>
      <Grid.Row style={{ display: 'flex', padding: '14px', justifyContent: 'space-between'}}>
        <Text as="h1" className="notPrintable" notr>Detailansicht</Text>
        <Image className='logo-compact-view' alt="Logo" src={logo.downloadUrl} rounded/>
      </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Text as="h2">analytics.title</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="notPrintable" columns={2}>
          <Grid.Column>
            <Text as="h2">analytics.correctAnswers</Text>
            <Grid>
              {
                exerciseAnalytics.map(renderDayRow)
              }
            </Grid>
          </Grid.Column>
          {!(location.state && location.state.isPlus) && 
            <Grid.Column>
              <Text as="h2">analytics.periodicAnswers</Text>
              {
                reviewAnalytics.map(renderReviewRow)
              }
            </Grid.Column>          
          }
        </Grid.Row>
        <Grid.Row className="printable" columns={1}>
          <Grid.Column>
            <Text as="h2">analytics.correctAnswers</Text>
            <Grid>
              {
                exerciseAnalytics.map(renderDayRow)
              }
            </Grid>
          </Grid.Column>
          {!(location.state && location.state.isPlus) && 
            <Grid.Column style={{ marginTop: '32px'}}>
              <Text as="h2">analytics.periodicAnswers</Text>
              {
                reviewAnalytics.map(renderReviewRow)
              }
            </Grid.Column>        
          }
        </Grid.Row>
      </Grid>
    </>
  )
}

/* PropTypes */
ClientAnalytics.propTypes = {}

ClientAnalytics.defaultProps = {}

/* Local utility functions */
const sortArrayByIndex = (a, b) => {

  if (!a || !b) {
    return
  }

  if (a.index > b.index) {
    return -1;
  }

  if (a.index < b.index) {
    return 1;
  }
  return 0;
}

const getCorrectCount = (answers, exerciseNumbers) => exerciseNumbers.reduce(
  (total, current) => {
    const totalCorrect = pathOr(0, [`exercise${current}`, 'totalCorrect'], answers)

    return total + totalCorrect
  }, 0)

const stepsToArray = object => {
  const pattern = /step(.*)/

  return Object.keys(object)
    .filter(name => pattern.test(name))
    .map(name => pattern.exec(name)[1])
    .map(index => object[`step${index}`])
}

const getReviewAnalyticsByDay = (answers = {}) =>  {
  const pattern = /exercise-reviewExercise-(.*)/

  const reviews = Object.keys(answers)
        .filter(name => pattern.test(name))
        

  return reviews
    .map(name => {
      return {
            answers: stepsToArray(answers[name]),
            index: parseInt(pattern.exec(name)[1]),
          }
        })
    .sort(sortArrayByIndex)
    .reverse()
}

const renderDayRow = ({ comment, correctSteps, dayNumber, totalSteps }) =>(
  <Grid.Row
    key={dayNumber}>
    <Grid.Column width={8}>
      <Text notr>Tag {dayNumber}</Text>
    </Grid.Column>
    <Grid.Column width={8}>
      <Text notr>{ correctSteps } korrekt { totalSteps }</Text>
    </Grid.Column>
    <Grid.Column style={{ paddingTop: '16px'}} width={16}>
      <Text notr>Kommentare: {comment}</Text>
    </Grid.Column>
  </Grid.Row>
) 
                                                                  

const renderReviewExercise = ({ button, comments }, i) => <Grid.Row id="asd" columns={3} key={i}>
                                                                    <Grid.Column width={1}>
                                                                      { i + 1 }
                                                                    </Grid.Column>
                                                                    <Grid.Column style={{ padding: 0, paddingRight: '8px' }}  width={4}>
                                                                      { reviewButtons[button - 1] }
                                                                    </Grid.Column>
                                                                    <Grid.Column style={{ padding: 0 }} width={11}>
                                                                      { comments }
                                                                    </Grid.Column>
                                                                  </Grid.Row>

const renderReviewRow = ({ answers, index }) => <Grid.Row columns={1} key={index}>
                                                  <Grid.Column>
                                                    <Text as="b" notr>Ergebnis nach Tag {index}</Text>
                                                  </Grid.Column>
                                                  <Grid.Column>
                                                    <Grid padded>
                                                      <Grid.Row columns={3}>
                                                        <Grid.Column width={1}>
                                                        <Text notr>Nr</Text>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                          <Text>analytics.comments</Text>
                                                        </Grid.Column>
                                                      </Grid.Row>
                                                      {
                                                        answers.map(renderReviewExercise)
                                                      }
                                                    </Grid>
                                                  </Grid.Column>
                                                </Grid.Row>



/* Local Styled Components */

export default ClientAnalytics
