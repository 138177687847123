/* Global imports */
import React from 'react'
import { Input as SemanticInput } from 'semantic-ui-react'

/* Local imports */
import I18n from 'Common/i18n'

/* Component definition */
export const Input = React.forwardRef(({ placeholder, ...props }, ref) => {
  return (
    <SemanticInput placeholder={I18n.t(placeholder)} ref={ref} {...props}/>
  )
})
/* PropTypes */

/* Local utility functions */

export default Input