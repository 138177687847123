/* Global imports */
import React from 'react'
import { array, string, number, oneOfType, object, bool } from 'prop-types'

/* Local imports */
import I18n from 'Common/i18n'

/* Component definition */
export const Text = ({ as: As, notr, children: value, vars, ...rest }) => {
  return (
    <As {...rest}>
      { getValue(!notr, value, vars) }
    </As>
  )
}
/* PropTypes */
Text.propTypes = {
  as:        string,
  children:  oneOfType([array, object, string, number]),
  className: string,
  notr:      bool,
}

Text.defaultProps = {
  as:   'span',
  notr: false,
}

/* Local utility functions */
export const getValue = (shouldTranslate, value, vars) => {

  // TODO: Allow variables
  return shouldTranslate ? I18n.t(value, vars) : value
}

export default Text
